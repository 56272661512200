import { makeStyles, shorthands, Subtitle1 } from '@fluentui/react-components';
import { WeatherMoon24Filled, WeatherSunny24Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { AppState, BrandedSettingsType, useClasses } from '../../../App';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import { toggleFeatureFlag } from '../../../redux/features/app/appSlice';
import { setSelectedConversation } from '../../../redux/features/conversations/conversationsSlice';
import { ChatList } from '../../chat/chat-list/ChatList';
import { HelpMenu } from '../../header/HelpMenu';
import { UserSettingsMenu } from '../../header/UserSettingsMenu';
import { SettingsDialog } from '../../header/settings-dialog/SettingsDialog';
import { PluginGallery } from '../../open-api-plugins/PluginGallery';
import Button from '../../shared/Button';
import { Loading } from '../../views';


const useCustomClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

export interface ChatProps {
    classes: ReturnType<typeof useClasses>;
    appState: AppState;
    setAppState: (state: AppState) => void;
    initialBrandingSettings: BrandedSettingsType;
}


interface MoreProps {
    isCollapsed: boolean;
    toggleCollapse: () => void;
    isLoading: boolean;
}

const AuthenticatedLayout = ({
    classes,
    appState,
    setAppState,
    initialBrandingSettings,
    isCollapsed,
    toggleCollapse,
    isLoading,
}: ChatProps & MoreProps) => {

    const { sessionId } = useParams<{ sessionId?: string }>();

    const { features } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const customClass = useCustomClasses();
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = React.useState(false);

    const darkMode = features[FeatureKeys.DarkMode];

    const handleDarkModeToggle = () => {
        dispatch(toggleFeatureFlag(FeatureKeys.DarkMode));
    };

    useEffect(() => {
        if (sessionId) {
        dispatch(setSelectedConversation(sessionId));
       }
    }, [dispatch, sessionId])

    return (
        <div className={classes.container}>
            <div className={classes.header} style={{ backgroundColor: initialBrandingSettings.headerBarColor }}>
                <div className={classes.logoContainer}>
                    <img
                        src={initialBrandingSettings.logoIconLink}
                        className={classes.logo}
                        style={{
                            maxWidth: '32px',
                            maxHeight: '32px',
                            width: 'auto',
                            height: 'auto',
                        }}
                    />

                    <Subtitle1
                        as="h1"
                        style={{
                            color: initialBrandingSettings.titleFontColor,
                        }}
                    >
                        {initialBrandingSettings.title}
                    </Subtitle1>
                </div>

                {/* {appState > AppState.SettingUserInfo && ( */}
                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        <PluginGallery />
                        <Button
                            onClick={handleDarkModeToggle}
                            size="medium"
                            icon={
                                darkMode.enabled ? (
                                    <WeatherSunny24Regular color="white" />
                                ) : (
                                    <WeatherMoon24Filled color="white" />
                                )
                            }
                            appearance="subtle"
                            shape="circular"
                            className={classes.darkModeButton}
                        />
                        <HelpMenu />

                        <UserSettingsMenu
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                        />
                    </div>
                </div>
                {/* )} */}
            </div>
            {/* {appState === AppState.ProbeForBackend && <BackendProbe onBackendFound={onBackendFound} />} */}
            {appState === AppState.SettingUserInfo && (
                <Loading text={'Hang tight while we fetch your information...'} />
            )}
            {/* {appState === AppState.ErrorLoadingUserInfo && (
                <Error text={'Unable to load user info. Please try signing out and signing back in.'} />
            )} */}
            {/* {appState === AppState.ErrorLoadingChats && (
                <Error text={'Unable to load chats. Please try refreshing the page.'} />
            )} */}
            {/* {appState === AppState.LoadingChats && <Loading text="Loading chats..." />} */}

            {/* {isLoading ? (
                <Loading text="Loading chat sessions..." />
            ) : ( */}
            <div className={customClass.container}>
                <ChatList isLoading={isLoading} isCollapsed={isCollapsed} onToggleCollapse={toggleCollapse} />
                <Outlet />
            </div>
            {/* )} */}

            {/* Initial Chat Configuration Settings  */}
            {/* {appState === AppState.Chat ? (
                <div className={customClass.container}>
                    <ChatList isCollapsed={isCollapsed} onToggleCollapse={toggleCollapse} />
                    <Outlet />
                </div>
            ) : null} */}
            {isSettingsDialogOpen && (
                <SettingsDialog
                    open={isSettingsDialogOpen}
                    closeDialog={() => {
                        setIsSettingsDialogOpen(false);
                    }}
                    defaultExpandedSection="branding"
                    isWelcomeView
                />
            )}
        </div>
    );
};

export default AuthenticatedLayout;
