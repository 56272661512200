import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import React from 'react';
import Button from './Button';

interface ConfirmButtonProps {
    actionButtonTitle: string;
    confirmModalTitle: string;
    confirmModalContent: string;
    confirmButtonTitle: string;
    onConfirm: () => void;
    loading?: boolean;
}

const ConfirmButton: React.FC<ConfirmButtonProps> = ({
    actionButtonTitle,
    confirmButtonTitle,
    confirmModalTitle,
    confirmModalContent,
    onConfirm,
    loading,
}) => {
    return (
        <Dialog modalType="alert">
            <DialogTrigger disableButtonEnhancement>
                <Button>{actionButtonTitle}</Button>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{confirmModalTitle}</DialogTitle>
                    <DialogContent>{confirmModalContent}</DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" disabled={loading}>
                                Close
                            </Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            onClick={onConfirm}
                            loading={loading}
                        >
                            {confirmButtonTitle}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default ConfirmButton;
