import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useChat } from '../hooks';


export interface ChatParticipant {
    name: string;
    email: string;
    userId: string;
}
export interface ShareChatVariables {
    participants: ChatParticipant[];
    chatId: string;
}

export const useShareChat = () => {
    const queryClient = useQueryClient();
    const { shareChat } = useChat();

    return useMutation({
        mutationFn: ({ chatId, participants }: ShareChatVariables) => shareChat(chatId, participants),
        onSuccess: (_, variables) => {
            void queryClient.invalidateQueries({
                queryKey: ['chatParticipants', variables.chatId],
            });
        },
    });
};
