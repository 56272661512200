/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { getFriendlyChatName } from '../../../../libs/hooks/useChat';
import { AlertType } from '../../../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { addAlert } from '../../../../redux/features/app/appSlice';
import { removeChatSession } from '../../../../redux/features/conversations/conversationsSlice';
import { Delete16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IEditChatNameProps {
    chatId: string;
}

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId }) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { conversations } = useAppSelector((state) => state.conversations);
    const chatName = getFriendlyChatName(conversations?.[chatId]);

    const onDeleteChat = () => {
        const message = `Chat session: ${chatName} has been deleted.`;
        void chat.deleteChat(chatId);
        dispatch(removeChatSession(chatId));
            dispatch(
                addAlert({
                    type: AlertType.Success,
                    message,
                }),
            );
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button icon={<Delete16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to delete chat: {chatName}?</DialogTitle>
                    <DialogContent>
                        This action will permanently delete the chat, and any associated resources and memories for all
                        participants.
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onDeleteChat}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
