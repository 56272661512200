import { shorthands, tokens } from '@fluentui/react-components';

const visibleScrollbarStyles = {
    '&::-webkit-scrollbar': {
        backgroundColor: tokens.colorNeutralBackground6,
        ...shorthands.borderRadius(tokens.spacingVerticalM),
    },
    '&::-webkit-scrollbar-thumb': {
        visibility: 'visible',
        backgroundColor: tokens.colorNeutralStroke1Pressed,
    },
    '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: tokens.colorNeutralStrokeAccessible,
    },
};

export { visibleScrollbarStyles };
