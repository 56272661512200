/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
import { Button } from '@fluentui/react-button';
import { Avatar, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { People20Regular } from '@fluentui/react-icons';
import React from 'react';
import { IChatParticipant } from '../../../../libs/models/ChatSession';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';


const useStyles = makeStyles({
    root: {
        width: '400px',
    },
    actions: {
        paddingTop: '2rem',
    },
    participantsList: {
        maxHeight: '300px',
        overflowY: 'auto',
        ...shorthands.gap('8px'),
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
    },
    participantItem: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.padding('8px'),
        ...shorthands.gap('12px'),
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        },
        ...shorthands.borderRadius('4px'),
    },
    participantInfo: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.flex(1),
    },
    participantName: {
        color: tokens.colorNeutralForeground1,
        fontWeight: tokens.fontWeightSemibold,
    },
    participantEmail: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase200,
    },
    youBadge: {
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.padding('2px', '8px'),
        ...shorthands.borderRadius('4px'),
    },
    emptyState: {
        textAlign: 'left',
        color: tokens.colorNeutralForeground3,
        ...shorthands.padding('16px'),
    },
});



interface ChatParticipantsDialogProps {
    participants: IChatParticipant[];
}

export const ChatParticipantsDialog: React.FC<ChatParticipantsDialogProps> = ({ participants }) => {
    const styles = useStyles();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    return (
        <Dialog>
            <DialogTrigger>
                <Tooltip content="Chat participants" relationship="label">
                    <Button icon={<People20Regular />} appearance="transparent" aria-label="View participants">
                        <label
                            style={{
                                fontSize: '12px',
                                fontWeight: 500,
                            }}
                        >
                            Participants
                        </label>
                    </Button>
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={styles.root}>
                <DialogBody>
                    <DialogTitle
                        style={{
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingBottom: 12,
                        }}
                    >
                        Chat Participants
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.participantsList}>
                            {participants.length > 0 ? (
                                participants.map((participant) => (
                                    <div key={participant.email} className={styles.participantItem}>
                                        <Avatar size={36} image={
                                            {
                                                src: participant.profileImage,
                                            }
                                        }  name={participant.name} />
                                        <div className={styles.participantInfo}>
                                            <span className={styles.participantName}>{participant.name}</span>
                                            <span className={styles.participantEmail}>{participant.email}</span>
                                        </div>
                                        {participant.email === activeUserInfo?.email ? (
                                            <span className={styles.youBadge}>You</span>
                                        ) : null}
                                    </div>
                                ))
                            ) : (
                                <div className={styles.emptyState}>No participants to display</div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={styles.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
