import { useMsal } from '@azure/msal-react';
import { Body1, Button, makeStyles, shorthands, Subtitle1, Title3, tokens } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import { getErrorDetails } from '../../components/utils/TextUtils';
import { BrandingSettings } from '../../libs/models/BrandingSettings';
import { useSharedClasses } from '../../styles';
import './Login.css';


export const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        ...shorthands.overflow('hidden'),
    },
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalS,
            display: 'flex',
        },
        height: '48px',
        justifyContent: 'space-between',
        width: '100%',
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    logo: {
        width: '28px',
        height: '28px',
        objectFit: 'cover',
        // marginLeft: '8px',
        marginRight: '0px',
        ...shorthands.borderRadius('4px'),
    },
});
interface LoginProps {
    brandingSettings: BrandingSettings;
}

 const Login: React.FC<LoginProps> = ({
brandingSettings,
}: LoginProps) => {
    const { instance } = useMsal();
    const {
        backgroundImage,
        loginHeaderTitle,
        loginHeaderTitleColor,
        loginBody,
        loginBodyColor,
        loginContainerColor,
        loginBTNName,
        loginBTNTextColor,
        loginBTNColor,
        loginBTNLogo,
        headerBarColor,
        logoIconLink,
        title,
        titleFontColor
    } = brandingSettings;
    const classes = useSharedClasses();
    const loginClasses = useClasses();

    const [styles, setStyles] = useState<React.CSSProperties>({});

    useEffect(() => {
        setStyles({
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            marginTop: 'auto',
            color: loginBodyColor,
            backgroundColor: loginContainerColor,
        });
    }, [backgroundImage, loginBodyColor, loginContainerColor]);

    const handleSignIn = () => {
        instance.loginRedirect().catch((e: unknown) => {
            alert(`Error signing in: ${getErrorDetails(e)}`);
        });
    };

    return (
        <div className={loginClasses.container}>
            <div className={loginClasses.header} style={{ backgroundColor: headerBarColor }}>
                <div className={loginClasses.logoContainer}>
                    <img
                        src={logoIconLink}
                        style={{
                            maxWidth: '32px',
                            maxHeight: '32px',
                            width: 'auto',
                            height: 'auto',
                        }}
                        className={loginClasses.logo}
                    />

                    <Subtitle1 as="h1" style={{ color: titleFontColor }}>
                        {title}
                    </Subtitle1>
                </div>
            </div>
            <div className={`${classes.informativeView} login-container`} style={{ ...styles, height: '100vh' }}>
                <div
                    className="container"
                    style={{
                        backgroundColor: loginContainerColor,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 20px rgba(0, 0, 0, 0.5)',
                    }}
                >
                    <div className="logo-container">
                        <h1 style={{ color: loginHeaderTitleColor }}>{loginHeaderTitle}</h1>
                    </div>
                    <Title3 style={{ color: loginBodyColor }}>{loginBody}</Title3> <br />
                    <Body1 style={{ color: loginBodyColor }}>
                        {/* {"Don't have an account? Create one for free at"}{' '}
                        <a
                            href="https://account.microsoft.com/"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none', fontWeight: 500, color: loginBodyColor }}
                        >
                            Create Account
                        </a> */}
                    </Body1>{' '}
                    {/* <br /> */}
                    {/* <center>
                        <Button
                            style={{
                                padding: 0,
                                marginTop: 50,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            appearance="transparent"
                            onClick={handleSignIn}
                            data-testid="signinButton"
                        >
                            <Image src={signInLogo} />
                        </Button>
                    </center> */}
                    <center>
                        <Button
                            onClick={handleSignIn}
                            appearance="transparent"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px',
                                borderRadius: '5px',
                                border: 'none',
                                backgroundColor: loginBTNColor,
                                color: loginBTNTextColor,
                                cursor: 'pointer',
                                marginTop: '50px',
                            }}
                        >
                            <img
                                src={loginBTNLogo}
                                alt="Logo"
                                style={{ marginRight: '10px', width: '25px', height: '25px' }}
                            />
                            <span style={{ fontSize: '17px' }}>{loginBTNName}</span>
                        </Button>
                    </center>
                </div>
            </div>
        </div>
    );
 };

export default Login;
