/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC, useState } from 'react';
import { ChatList } from '../chat/chat-list/ChatList';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'start',
        height: '100%',
    },
});

interface chatRoomData {
    initialMSG: string;
}


const ChatView: FC<chatRoomData> = () => {
    const classes = useClasses();
    const [isCollapsed, setIsCollapsed] = useState(false);



    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div className={classes.container}>
            <ChatList isCollapsed={isCollapsed} onToggleCollapse={toggleCollapse} />
            {/* {selectedId !== '' && (
                <ChatWindow initialMSG={initialMSG} userPhotoUrl={avatarUrl} isCollapsed={isCollapsed} />
            )} */}
        </div>
    );
};


export default ChatView;
