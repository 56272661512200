/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { UserSchema } from './useGraph';

interface UseUserSuggestionsOptions {
    enabled?: boolean;
    minLength?: number;
    debounceMs?: number;
}

export const useUserSuggestions = (
    query: string,
    fetchUsers: (query: string) => Promise<UserSchema[]>,
    options: UseUserSuggestionsOptions = {},
) => {
    const { minLength = 2, debounceMs = 300, enabled = true } = options;

    const debouncedQueryKey = useCallback((query: string) => {
        return debounce(() => query, debounceMs)();
    }, [debounceMs]);

    return useQuery({
        queryKey: ['userSuggestions', debouncedQueryKey(query)],
        queryFn: () => fetchUsers(query),
        enabled: enabled && query.length >= minLength,
        // staleTime: 5 * 60 * 1000, // Consider results stale after 5 minutes
        // gcTime: 10 * 60 * 1000, // Keep unused data in cache for 10 minutes
        retry: 1, // Only retry once on failure
        select: (users) =>
            users.map((user) => ({
                email: user.mail ?? user.userPrincipalName,
                name: user.displayName,
                department: user.jobTitle,
                id: user.id,
            })),
    });
};

export interface UserSuggestion {
    email: string;
    name: string;
    department?: string | null;
    id: string;
}
