import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ChatWindow } from '../../chat/ChatWindow';




const ChatView: FC = () => {
    const { sessionId } = useParams<{ sessionId?: string }>();

    return (
        <>
            {sessionId && sessionId !== '' && (
                <ChatWindow  sessionId={sessionId}>
                    <Outlet />
                </ChatWindow>
            )}
        </>
    );
};

export default ChatView;
