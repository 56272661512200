
export const detectCode = (text: string): string[] => {
    const codeRegex = /```([\s\S]*?)```/g;
    const matches = [];
    let match;

    while ((match = codeRegex.exec(text)) !== null) {
        matches.push(match[1].trim());
    }

    return matches;
};
