/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMsal } from '@azure/msal-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useGetAccessToken } from '../../hooks';
import { useAppDispatch } from '../../redux/app/hooks';
import { addAlert } from '../../redux/features/app/appSlice';
import { editConversationAvatar, updateSelectedConversation } from '../../redux/features/conversations/conversationsSlice';
import { useGraph } from '../hooks';
import { AlertType } from '../models/AlertType';
import { IPersona } from '../models/Persona';
import { PersonaService } from '../services/PersonaService';

const personaService = new PersonaService();

export const usePersonaOperations = (
    sessionId: string | undefined,
    setPreviousSessionId: React.Dispatch<React.SetStateAction<string | null>>,
    setSelectedPersonaId: React.Dispatch<React.SetStateAction<string | null>>,
    notify: ({ title, body, subtitle, type }: any) => void,
    setShowPersonaManagement: (value: React.SetStateAction<boolean>) => void,
    chatId?: string,
    currentPersona?: IPersona | null,
    modificationHandler?: (persona: IPersona) => Promise<void>,
    disablePersonaHandler?: () => Promise<void>,
    generalSettings?: any,
    personas?: IPersona[],
) => {
    const { getAccessToken } = useGetAccessToken();
    const { instance } = useMsal();
    const { findUserByEmail, findUserById } = useGraph();
    const queryClient = useQueryClient();
    const dispatch = useAppDispatch();

    const getUserInfo = useCallback(async () => {
        const accessToken = await getAccessToken();
        const account = instance.getActiveAccount();
        return {
            accessToken,
            userId: account?.idTokenClaims?.oid,
        };
    }, [getAccessToken, instance]);

    // Save/Update Persona Mutation
    const savePersonaMutation = useMutation({
        mutationFn: async ({
            newImageUrl,
            newPersona,
        }: {
            newImageUrl?: IPersona['imageUrl'];
            newPersona?: IPersona;
        }) => {
            const { accessToken, userId } = await getUserInfo();
            const imageUrl = newImageUrl ?? currentPersona?.imageUrl;

            const data: IPersona = newPersona ?? {
                id: currentPersona?.id ?? '',
                userId: userId?.toString() ?? '',
                name: currentPersona?.name ?? '',
                imageUrl: imageUrl ?? '',
                prompt: currentPersona?.prompt ?? '',
                sharedWithUsers: [],
                ...generalSettings,
            };

            const response = await personaService.UpsertPersonaAsync(data, accessToken);
            return response
        },
        onSuccess: async (response, { newPersona }) => {
            if (newPersona) {
                setSelectedPersonaId(response.persona.id);
                setPreviousSessionId(sessionId ?? '');
            }
            await queryClient.invalidateQueries({ queryKey: ['personas'] });
            await queryClient.invalidateQueries({ queryKey: ['persona', currentPersona?.id] });
            await queryClient.invalidateQueries({ queryKey: ['messages', chatId] });
            await queryClient.invalidateQueries({ queryKey: ['chatSessions'] });

            notify({
                type: 'success',
                title: 'Success',
                body: newPersona ? 'Persona has been created successfully' : 'Persona has been updated successfully',
            });
        },
        onError: (error) => {
            console.error('Error saving persona:', error);
            notify({ title: 'Error', body: 'Error saving persona', type: 'error' });
        },
    });

    // Delete Persona Mutation
    const deletePersonaMutation = useMutation({
        mutationFn: async (id?: IPersona['id']) => {
            const { accessToken, userId } = await getUserInfo();
            const persona = id
                ? personas?.find((x) => x.id === id)
                : {
                      id: currentPersona?.id ?? '',
                      userId: userId?.toString() ?? '',
                      name: currentPersona?.name ?? '',
                      imageUrl: currentPersona?.imageUrl ?? '',
                      prompt: currentPersona?.prompt ?? '',
                      sharedWithUsers: [],
                      intentFrequencyPenalty: currentPersona?.intentFrequencyPenalty ?? 0,
                      responseTemperature: currentPersona?.responseTemperature ?? 0,
                      responseTopP: currentPersona?.responseTopP ?? 0,
                      responsePresencePenalty: currentPersona?.responsePresencePenalty ?? 0,
                      responseFrequencyPenalty: currentPersona?.responseFrequencyPenalty ?? 0,
                      intentTemperature: currentPersona?.intentTemperature ?? 0,
                      intentTopP: currentPersona?.intentTopP ?? 0,
                      intentPresencePenalty: currentPersona?.intentPresencePenalty ?? 0,
                  };

            if (!persona) throw new Error('Persona not found');
            await personaService.DeletePersonaAsync(persona, accessToken);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['personas'] });
            setSelectedPersonaId('');
            setShowPersonaManagement(false);
            // setCurrentPersona(undefined);
            notify({ title: 'Success', body: 'Persona has been deleted successfully', type: 'success' });
        },
        onError: (error) => {
            console.error('Error:', error);
            notify({ title: 'Error', body: 'Error while deleting a persona', type: 'error' });
        },
    });

    // Set Persona Mutation
    const setPersonaMutation = useMutation({
        mutationFn: async (persona: IPersona) => {
            if (!modificationHandler) throw new Error('Modification handler not provided');
            await modificationHandler(persona);
            return persona;
        },
        onSuccess: async (persona) => {
           await queryClient.invalidateQueries({ queryKey: ['chatSessions'] });

            dispatch(
                updateSelectedConversation({
                    id: chatId ?? '',
                    systemDescription: persona.prompt,
                    selectedPersonaId: persona.id,
                    personaName: persona.name,
                    imageUrl: persona.imageUrl,
                    intentFrequencyPenalty: persona.intentFrequencyPenalty,
                    responseTemperature: persona.responseTemperature,
                    responseTopP: persona.responseTopP,
                    responsePresencePenalty: persona.responsePresencePenalty,
                    responseFrequencyPenalty: persona.responseFrequencyPenalty,
                    intentTemperature: persona.intentTemperature,
                    intentTopP: persona.intentTopP,
                    intentPresencePenalty: persona.intentPresencePenalty,
                }),
            );

            dispatch(
                editConversationAvatar({
                    id: chatId ?? "",
                    newImageUrl: persona.imageUrl,
                }),
            );

            notify({ title: 'Success', body: 'Persona has been set successfully', type: 'success' });
        },
        onError: (error: Error) => {
            console.error('Error:', error);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message: `Error saving the new prompt: ${error.message}`,
                }),
            );
            notify({ title: 'Error', body: 'Error while setting persona', type: 'error' });
        },
    });

    // Disable Persona Mutation
    const disablePersonaMutation = useMutation({
        mutationFn: async () => {
            if (!disablePersonaHandler) throw new Error('Disable handler not provided');
            await disablePersonaHandler();
        },
        onSuccess: () => {
            dispatch(
                updateSelectedConversation({
                    id: chatId ?? '',
                    systemDescription: null,
                    selectedPersonaId: null,
                    personaName: null,
                    imageUrl: null,
                    intentFrequencyPenalty: null,
                    responseTemperature: null,
                    responseTopP: null,
                    responsePresencePenalty: null,
                    responseFrequencyPenalty: null,
                    intentTemperature: null,
                    intentTopP: null,
                    intentPresencePenalty: null,
                }),
            );
            notify({ title: 'Success', body: 'Persona has been disabled successfully', type: 'success' });
        },
        onError: (error: Error) => {
            console.error('Error:', error);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message: `Error disabling the persona: ${error.message}`,
                }),
            );
            notify({ title: 'Error', body: 'Error while disabling persona', type: 'error' });
        },
    });

    // Share Persona Mutation
    const sharePersonaMutation = useMutation({
        mutationFn: async (email: string) => {
            if (!currentPersona?.id) throw new Error('No persona selected');
            const { accessToken } = await getUserInfo();
            const userId = await findUserByEmail(email);

            if (userId.length === 0) throw new Error('User does not exist');
            if (currentPersona.sharedWithUsers.includes(userId)) {
                throw new Error('Persona already shared with user');
            }

            return personaService.SharePersonaWithUserAsync(currentPersona.id, userId, accessToken);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['personas'] });
            await queryClient.invalidateQueries({ queryKey: ['persona', currentPersona?.id] });
            notify({ title: 'Success', body: 'Persona has been shared successfully', type: 'success' });
        },
        onError: (error) => {
            console.error('Failed to share persona', error);
            notify({
                title: 'Error',
                body: error instanceof Error ? error.message : 'Error while sharing persona',
                type: 'error',
            });
        },
    });

    // Unshare Persona Mutation
    const unsharePersonaMutation = useMutation({
        mutationFn: async (email: string) => {
            if (!email || !currentPersona?.id) throw new Error('Invalid parameters');
            const { accessToken } = await getUserInfo();
            const userId = await findUserByEmail(email);

            if (!userId) throw new Error('User not found');
            return personaService.UnsharePersonaWithUserAsync(currentPersona.id, userId, accessToken);
        },
        onSuccess: async () => {
           await queryClient.invalidateQueries({ queryKey: ['personas'] });
           await  queryClient.invalidateQueries({ queryKey: ['persona', currentPersona?.id] });
            notify({ title: 'Success', body: 'Persona has been unshared successfully', type: 'success' });
        },
        onError: (error) => {
            console.error('Failed to unshare persona', error);
            notify({ title: 'Error', body: 'Error while unsharing persona', type: 'error' });
        },
    });

    // Shared Users Query
    const { data: sharedUsers, isLoading: isLoadingSharedUsers } = useQuery({
        queryKey: ['sharedUsers', currentPersona?.id],
        queryFn: async () => {
            if (!currentPersona?.sharedWithUsers.length) return [];
            const promises = currentPersona.sharedWithUsers.map((id) => findUserById(id));
            return Promise.all(promises);
        },
        enabled: !!currentPersona?.sharedWithUsers.length,
    });

    return {
        // Mutations
        savePersona: savePersonaMutation.mutate,
        deletePersona: deletePersonaMutation.mutate,
        setPersona: setPersonaMutation.mutate,
        disablePersona: disablePersonaMutation.mutate,
        sharePersona: sharePersonaMutation.mutate,
        unsharePersona: unsharePersonaMutation.mutate,

        // Loading states
        isSaving: savePersonaMutation.isPending,
        isDeleting: deletePersonaMutation.isPending,
        isSetting: setPersonaMutation.isPending,
        isDisabling: disablePersonaMutation.isPending,
        isSharing: sharePersonaMutation.isPending,
        isUnsharing: unsharePersonaMutation.isPending,

        // Shared users data
        sharedUsers,
        isLoadingSharedUsers,

        // Error states
        saveError: savePersonaMutation.error,
        deleteError: deletePersonaMutation.error,
        setError: setPersonaMutation.error,
        disableError: disablePersonaMutation.error,
        shareError: sharePersonaMutation.error,
        unshareError: unsharePersonaMutation.error,
    };
};
