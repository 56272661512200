import { ToastIntent, useId, useToastController } from '@fluentui/react-components';
import Toast from './Toast';

interface NotifyParams {
    title?: string;
    body?: string;
    subtitle?: string;
    type: ToastIntent;
}

export function useNotify() {
    const toasterId = useId('toaster');
    const { dispatchToast } = useToastController(toasterId);

    const notify = ({ title, body, subtitle, type = 'info' }: NotifyParams) => {
        dispatchToast(<Toast title={title} body={body} subtitle={subtitle} />, { intent: type });
    };

    return { notify, toasterId };
}
