/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Card, LargeTitle, Textarea, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Info20Regular } from '@fluentui/react-icons';
import { useState } from 'react';
import { useLoading } from '../../../hooks';
import { IPersona } from '../../../libs/models/Persona';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import Button from '../../shared/Button';
import ConfirmDialog from '../shared/ConfirmDialog';
import PersonaAvatarView from './PersonaAvatarView';

interface PersonaViewProps {
    persona: IPersona;
    onPersonaManage: (id: IPersona['id']) => void;
    handleSetPersona: (id: IPersona['id']) => void;
    handleDeletePersona: (id: IPersona['id']) => void;
    handleDisablePersona: (id: IPersona['id']) => void;
    activePersonaId: IPersona['id'];
    isDeleting: boolean;
    isSetting: boolean;
}

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding('16px'),
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        maxHeight: '100px',
        ...shorthands.gap(tokens.spacingVerticalXXL),
    },
    body: {
        wordWrap: 'break-word',
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalM),
    },
});

const PersonaView: React.FC<PersonaViewProps> = ({
    persona,
    onPersonaManage,
    handleSetPersona,
    handleDeletePersona,
    activePersonaId,
    isDeleting,
    isSetting,
}) => {
    const classes = useClasses();
    const [id, setId] = useState<IPersona['id'] | undefined>(undefined);
    const { loading: deleting, handleLoading: handleDeleteLoading } = useLoading();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state?.conversations);

    const loggedInUserId = activeUserInfo?.id?.split?.('.')[0];
    const isOwner = persona.userId === loggedInUserId;

    const isPersonaActive = (id: IPersona['id']) => id === activePersonaId;
    const chatState = conversations?.[selectedId];

    const onDelete = () => {
        if (!id) {
            return;
        }
        handleDeleteLoading(true);
        handleDeletePersona(id);
        setId(undefined);
        handleDeleteLoading(false);
    };

    return (
        <Card>
            <div className={classes.container}>
                <div className={classes.header}>
                    <PersonaAvatarView imageUrl={persona.imageUrl} />
                    <LargeTitle>{persona.name}</LargeTitle>
                </div>
                <Textarea resize="vertical" value={persona.prompt} rows={8} className={classes.body} readOnly />
                <div className={classes.actionButtons}>
                    <Button
                        onClick={() => {
                            onPersonaManage(persona.id);
                        }}
                    >
                        Manage Persona
                    </Button>

                    <Button
                        disabled={isSetting || isPersonaActive(persona.id) || chatState?.selectedPersonaId ? true : false}
                        onClick={() => {
                            handleSetPersona(persona.id);
                        }}
                    >
                        Set Persona
                    </Button>

                    {chatState?.selectedPersonaId ? (
                        <Tooltip relationship="label" content="Start a new chat session to set a new persona">
                            <Info20Regular />
                        </Tooltip>
                    ) : null}

                    <Button
                        onClick={() => {
                            setId(persona.id);
                        }}
                        disabled={!isOwner || isDeleting}
                    >
                        Delete Persona
                    </Button>
                </div>
            </div>
            <ConfirmDialog
                open={Boolean(id)}
                modalType="alert"
                title="Are you sure?"
                text="You are about to delete this persona"
                okText="Delete"
                onOk={() => {
                    onDelete();
                }}
                onCancel={() => {
                    setId(undefined);
                }}
                loading={deleting || isDeleting}
            />
        </Card>
    );
};

export default PersonaView;
