import { Card, LargeTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { useLoading } from '../../../../hooks';
import { styleConstants } from '../../../../libs/constants';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import Button from '../../../shared/Button';
import PersonaAvatarView from '../PersonaAvatarView';
import PersonaPopoverTitle from '../PersonaPopoverTitle';
import PersonaSettingsSlider from '../PersonaSettingsSlider';
import { IPersonaSettings } from '../types';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding(tokens.spacingVerticalM),
        ...styleConstants.visibleScrollbarStyles,
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        maxHeight: '100px',
        ...shorthands.gap(tokens.spacingVerticalXXL),
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: 'auto',
    },
    fieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const intentTemperature =
    'Temperature controls the “creativity” or randomness of the text generated by the AI model. \n\n A higher temperature (e.g., 0.7) results in more diverse and creative output. \n\n A lower temperature (e.g., 0.2) makes the output more deterministic and focused.';
const intentTopP =
    'TopP sampling is a method used in AI models. It only considers a certain group of tokens, based on their combined probability (top_p). \n\n For instance, if top_p is 0.1, the model only looks at the top 10% probable tokens for the next prediction.';
const intentPresencePenalty =
    'The presence penalty encourages the intent detection model to avoid overusing specific intents. \n\n If the presence penalty is set to a high value (e.g., 0.8), the model is heavily penalized for frequent repetition of the same intent, promoting more varied and contextually appropriate intent predictions. \n\n A low presence penalty value (e.g., 0.2) applies less penalty for repeating the same intent, which might result in the model predicting the same intent more often, even when the user’s requests vary.';
const intentFrequencyPenalty =
    "The frequency penalty affects how creative or predictable the model's responses are. \n\n A high frequency penalty (e.g., 1.5) makes the model stick to common language patterns and avoid rare words. \n\n A low frequency penalty (e.g., 0.5) allows the model to be more creative and use less common words.";

const responseTemperature =
    'Temperature controls the “creativity” or randomness of the text generated by the AI model. \n\n A higher temperature (e.g., 0.7) results in more diverse and creative output. \n\n A lower temperature (e.g., 0.2) makes the output more deterministic and focused.';
const responseTopP =
    'TopP sampling is a method used in AI models. It only considers a certain group of tokens, based on their combined probability (top_p). \n\n For instance, if top_p is 0.1, the model only looks at the top 10% probable tokens for the next prediction.';
const responsePresencePenalty =
    'The presence penalty encourages the intent detection model to avoid overusing specific intents. \n\n If the presence penalty is set to a high value (e.g., 0.8), the model is heavily penalized for frequent repetition of the same intent, promoting more varied and contextually appropriate intent predictions. \n\n A low presence penalty value (e.g., 0.2) applies less penalty for repeating the same intent, which might result in the model predicting the same intent more often, even when the user’s requests vary.';
const responseFrequencyPenalty =
    "The frequency penalty affects how creative or predictable the model's responses are. \n\n A high frequency penalty (e.g., 1.5) makes the model stick to common language patterns and avoid rare words. \n\n A low frequency penalty (e.g., 0.5) allows the model to be more creative and use less common words.";

const PersonaAdvancedSettings: React.FC<IPersonaSettings> = ({
    persona,
    generalSettings,
    handleConfigurationChange,
    handleSavePersona,
    isDeleting
}) => {
    const classes = useClasses();
    const { loading, handleLoading } = useLoading();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const loggedInUserId = activeUserInfo?.id.split('.')[0];
    const isOwner = persona?.userId === loggedInUserId;


    const canSave = persona?.prompt && persona.name && !loading;

    const handleSave = () => {
        if (!handleSavePersona) {
            return;
        }

        handleLoading(true);
        handleSavePersona();
        handleLoading(false);
    };

    return (
        <Card>
            <div className={classes.container}>
                <div className={classes.header}>
                    <PersonaAvatarView imageUrl={persona?.imageUrl} />
                    <LargeTitle>{persona?.name}</LargeTitle>
                </div>
                <PersonaPopoverTitle
                    title="Intent"
                    popoverText="Intent refers to the purpose or goal behind a user’s input or request."
                    style={{ marginTop: '20px' }}
                />
                <div className={classes.fieldsWrapper}>
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={intentTemperature}
                        label="Intent Temperature"
                        disabled={!isOwner}
                        name="intentTemperature"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={intentTopP}
                        label="Intent TopP"
                        disabled={!isOwner}
                        name="intentTopP"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={intentPresencePenalty}
                        label="Intent Presence Penalty"
                        disabled={!isOwner}
                        name="intentPresencePenalty"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={intentFrequencyPenalty}
                        label="Intent Frequency Penalty"
                        disabled={!isOwner}
                        name="intentFrequencyPenalty"
                        isDeleting={isDeleting}
                    />
                </div>

                <PersonaPopoverTitle
                    title="Response"
                    popoverText="Response refers to the text generated by the AI model in response to a user’s input or
                            request."
                    style={{ marginTop: '20px' }}
                />
                <div className={classes.fieldsWrapper}>
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={responseTemperature}
                        label="Response Temperature"
                        disabled={!isOwner}
                        name="responseTemperature"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={responseTopP}
                        label="Response TopP"
                        disabled={!isOwner}
                        name="responseTopP"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={responsePresencePenalty}
                        label="Response Presence Penalty"
                        disabled={!isOwner}
                        name="responsePresencePenalty"
                        isDeleting={isDeleting}
                    />
                    <PersonaSettingsSlider
                        generalSettings={generalSettings}
                        handleConfigurationChange={handleConfigurationChange}
                        popoverText={responseFrequencyPenalty}
                        label="Response Frequency Penalty"
                        disabled={!isOwner}
                        name="responseFrequencyPenalty"
                        isDeleting={isDeleting}
                    />
                </div>

                <div className={classes.actionButtons}>
                    <Button
                        onClick={() => {
                             handleSave();
                        }}
                        appearance="primary"
                        disabled={!canSave || !isOwner}
                        loading={loading}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default PersonaAdvancedSettings;
