import { Button } from '@fluentui/react-button';
import { Avatar, Text, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { Share20Regular } from '@fluentui/react-icons';
import { useQueryClient } from '@tanstack/react-query';
import { KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useShareChat } from '../../../../libs/api';
import { useGraph, useUserSuggestions } from '../../../../libs/hooks';
import { UserSuggestion } from '../../../../libs/hooks/useUserSuggestions';
import { AlertType } from '../../../../libs/models/AlertType';
import { useAppDispatch } from '../../../../redux/app/hooks';
import { addAlert } from '../../../../redux/features/app/appSlice';



const useClasses = makeStyles({
    root: {
        width: '550px',
    },
    actions: {
        paddingTop: '2rem',
    },
    inputContainer: {
        width: '100%',
        marginTop: '1rem',
        position: 'relative',
    },
    recipientInput: {
        width: '95%',
        minHeight: '40px',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        ...shorthands.gap('0.25rem'),
        ...shorthands.padding('4px', '8px'),
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.borderRadius('4px'),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        '&:focus-within': {
            borderBottomColor: tokens.colorBrandStroke1,
        },
    },
    textInput: {
        ...shorthands.flex('1 1 auto'),
        ...shorthands.border('none'),
        ...shorthands.outline('none'),
        backgroundColor: 'transparent',
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
        ':focus': {
            ...shorthands.borderColor(tokens.colorBrandStroke1),
        },
        minWidth: '200px',
        '&::placeholder': {
            color: tokens.colorNeutralForeground3,
        },
    },
    chip: {
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: tokens.colorNeutralBackground3,
        color: tokens.colorNeutralForeground1,
        ...shorthands.borderRadius('16px'),
        ...shorthands.padding('6px', '10px'),
        fontSize: tokens.fontSizeBase200,
        ...shorthands.gap('4px'),
        '& button': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '16px',
            height: '16px',
            backgroundColor: 'none',
            ...shorthands.border('none'),
            cursor: 'pointer',
            color: tokens.colorNeutralForeground3,
            ...shorthands.padding('2px'),
            '&:hover': {
                color: tokens.colorNeutralForeground1,
                backgroundColor: tokens.colorNeutralBackground4,
                ...shorthands.borderRadius('50%'),
            },
        },
    },
    suggestionsList: {
        width: '98%',
        maxHeight: '150px',
        overflowY: 'auto',
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('4px'),
        ...shorthands.margin('4px', '0'),
        ...shorthands.padding("3px"),
        boxShadow: tokens.shadow2,
    },
    suggestionHeader: {
        ...shorthands.padding('8px', '12px'),
        ...shorthands.borderBottom(`1px solid ${tokens.colorNeutralStroke2}`),
    },
    suggestionInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    suggestionItem: {
        ...shorthands.padding('8px', '12px'),
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        ...shorthands.gap('10px'),
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground2,
        },
    },
    suggestionName: {
        // fontWeight: tokens.fontWeightMedium,
        color: tokens.colorNeutralForeground1,
    },
    suggestionEmail: {
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
    },
    chipAvatar: {
        width: '16px',
        height: '16px',
        ...shorthands.borderRadius('50%'),
    },
});

interface IShareChatProps {
    chatId: string;
}

interface Recipient {
    email: string;
    name: string;
    id: string;
    profileImage?: string;
}

export const ShareChatDialog: React.FC<IShareChatProps> = ({chatId}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const [recipients, setRecipients] = useState<Recipient[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const suggestionsRef = useRef<HTMLDivElement>(null);

    const { fetchUsers } = useGraph();

    const { mutateAsync, isPending } = useShareChat()

    const queryClient = useQueryClient();

       const {
           data: suggestions = [],
           isError,
           error,
           isLoading,
       } = useUserSuggestions(inputValue, fetchUsers, {
           minLength: 2, // Only start searching when user types 2 or more characters
           debounceMs: 300, // Debounce API calls by 300ms
           enabled: inputValue.length > 0,
       });


    const updatedSuggestions = useMemo(() => {
        return suggestions.filter((suggestion) => !recipients.some((r) => r.email === suggestion.email));
    }, [recipients, suggestions])



       useEffect(() => {
           const handleClickOutside = (event: MouseEvent) => {
               if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
                   setIsPopoverOpen(false);
               }
           };

           document.addEventListener('mousedown', handleClickOutside);
           return () => {
               document.removeEventListener('mousedown', handleClickOutside);
           };
       }, []);

       const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
           if (event.key === 'Backspace' && inputValue === '' && recipients.length > 0) {
               const newRecipients = [...recipients];
               newRecipients.pop();
               setRecipients(newRecipients);
           }
       };


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setIsPopoverOpen(value.length > 0);

    };

    const addRecipient = (suggestion: UserSuggestion) => {
        if (!recipients.some((r) => r.email === suggestion.email)) {
            setRecipients([...recipients, { email: suggestion.email, name: suggestion.name, id: suggestion.id,  }]);
        }
        setInputValue('');
        setIsPopoverOpen(false);
        inputRef.current?.focus();
    };

    const removeRecipient = (email: string) => {
        setRecipients(recipients.filter((r) => r.email !== email));
    };

    const handleShare = async () => {
       try {
           const recipientsList = recipients.map((r) => r.name).join(', ');

           const newParticipants = recipients.map((r) => ({
               name: r.name,
               email: r.email,
               userId: r.id,
           }));

           await mutateAsync({ chatId, participants: newParticipants });

            await queryClient.invalidateQueries({
                queryKey: ['chatSessions'],
            });

         dispatch(
             addAlert({
                 type: AlertType.Success,
                 message: `Chat shared with ${recipientsList}`,
             }),
         );
       } catch (error) {
            console.error('Error sharing chat', error);
       }
    };

    return (
        <Dialog>
            <DialogTrigger>
                <Tooltip content="Share chat" relationship="label">
                    <Button style={{
                        marginRight: "-9px",
                        marginLeft: "-5px",
                    }} icon={<Share20Regular />} appearance="transparent" aria-label="Share" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Share Chat</DialogTitle>
                    <DialogContent
                        style={{
                            maxHeight: '350px',
                        }}
                    >
                        <div>Share this chat - Enter at least first 2 characters of email or name.</div>
                        <div className={classes.inputContainer}>
                            <div className={classes.recipientInput}>
                                {recipients.map((recipient) => (
                                    <span key={recipient.email} className={classes.chip}>
                                        <Avatar size={20} name={recipient.name} />
                                        {recipient.name}
                                        <button
                                            onClick={() => {
                                                removeRecipient(recipient.email);
                                            }}
                                            aria-label={`Remove ${recipient.name}`}
                                        >
                                            ×
                                        </button>
                                    </span>
                                ))}
                                <input
                                    ref={inputRef}
                                    className={classes.textInput}
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type email address or name..."
                                    aria-label="Recipients input"
                                />
                            </div>
                            {isPopoverOpen && (
                                <div ref={suggestionsRef} className={classes.suggestionsList}>
                                    <div className={classes.suggestionHeader}>
                                        <Text size={200} weight="semibold">
                                            {isLoading ? 'Searching...' : 'Suggested people'}
                                        </Text>
                                    </div>
                                    {isError ? (
                                        <div className={classes.suggestionItem}>
                                            <Text>Error loading suggestions: {error.message}</Text>
                                        </div>
                                    ) : isLoading ? (
                                        <div className={classes.suggestionItem}>
                                            <Text>Loading...</Text>
                                        </div>
                                    ) : updatedSuggestions.length === 0 ? (
                                        <div className={classes.suggestionItem}>
                                            <Text>No suggestions found</Text>
                                        </div>
                                    ) : (
                                        <>
                                            {updatedSuggestions.map((suggestion) => (
                                                <div
                                                    key={suggestion.email}
                                                    className={classes.suggestionItem}
                                                    onClick={() => {
                                                        addRecipient(suggestion);
                                                    }}
                                                >
                                                    <Avatar size={32} name={suggestion.name} />
                                                    <span className={classes.suggestionInfo}>
                                                        <span className={classes.suggestionName}>
                                                            {suggestion.name}{' '}
                                                            {suggestion.department && `- ${suggestion.department}`}
                                                        </span>
                                                        <span className={classes.suggestionEmail}>
                                                            {suggestion.email}
                                                        </span>
                                                    </span>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger>
                            <Button
                                appearance="primary"
                                onClick={() => {
                                    void handleShare();
                                }}
                                disabled={recipients.length === 0 || isPending}
                            >
                                Share
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
