import { Spinner, makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';
import { useGetAccessToken, useLoading } from '../../../hooks';
import { IPersona } from '../../../libs/models/Persona';
import { PersonaService } from '../../../libs/services/PersonaService';
import { DefaultAvatar } from '../../shared/BundledIcons';

interface PersonaAvatarViewProps {
    imageUrl?: IPersona['imageUrl'];
    handleImageUpload?: (url: IPersona['imageUrl']) => void;
    personaId?: IPersona['id'];
    isOwner?: boolean;
}

const personaService = new PersonaService();

const useClasses = makeStyles({
    container: {
        width: '100px',
        height: '100px',
        maxWidth: '100px',
        maxHeight: '100px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: '0',
    },
    personaAvatar: {
        width: '100px',
        height: '100px',
        ...shorthands.borderRadius('99999px'),
    },
    fileInput: {
        opacity: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        cursor: 'pointer',
    },
});

const PersonaAvatarView: React.FC<PersonaAvatarViewProps> = ({ imageUrl, handleImageUpload, personaId,isOwner }) => {
    const classes = useClasses();
    const { getAccessToken } = useGetAccessToken();
    const { loading, handleLoading } = useLoading();

    const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0 && personaId) {
            try {
                handleLoading(true);
                const file = event.target.files[0];
                const accessToken = await getAccessToken();
                if (imageUrl) {
                    await personaService.DeleteImageAsync(personaId, accessToken);
                }
                const response = await personaService.uploadPersonaImage(file, personaId, accessToken);
                const image = response.imageUrl;

                if (handleImageUpload) {
                    handleImageUpload(image);
                }
            } catch (error) {
                console.log('Error while uploading persona image: ', error);
            } finally {
                handleLoading(false);
            }
        }

        event.target.value = '';
    };

    const displayedImage = imageUrl ? (
        <img className={classes.personaAvatar} src={imageUrl} alt="Persona view" />
    ) : (
        <DefaultAvatar fontSize={100} className={classes.personaAvatar} filled />
    );

    return (
        <div className={classes.container}>
            {loading ? <Spinner size="tiny" style={{ height: '100%' }} /> : displayedImage}
            {handleImageUpload && isOwner && (  
                <input
                    type="file"
                    id="icon-upload"
                    accept="image/*"
                    onChange={(event) => void handleUpload(event)}
                    className={classes.fileInput}
                />
            )}
        </div>
    );
};

export default PersonaAvatarView;
