/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useMsal } from '@azure/msal-react';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useGetAccessToken } from '../../hooks';
import { useLocalStorage } from '../hooks';
import { IPersona } from '../models/Persona';
import { PersonaService } from '../services/PersonaService';

const personaService = new PersonaService();

export const useGetPersona = (selectedPersonaId: string | null) => {
    const { getAccessToken } = useGetAccessToken();
    const { value: currentPersona, setValue: setCurrentPersona } = useLocalStorage<IPersona | null>(
        'currentPersona',
        null,
    );

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ['persona', selectedPersonaId],
        queryFn: async () => {
            if (!selectedPersonaId) {
                return;
            }
            const accessToken = await getAccessToken();
            const persona = await personaService.GetPersonaById(accessToken, selectedPersonaId);
            return persona;
        },
    });

    useEffect(() => {
        if (data) {
            setCurrentPersona(data);
        }
    }, [data, setCurrentPersona]);

    return { currentPersona, setCurrentPersona, isLoading, error, refetchSessions: refetch };
};

export const useGetPersonas = () => {
    const { getAccessToken } = useGetAccessToken();
    const { instance } = useMsal();

    const sortPersonas = (personas: IPersona[]) => {
        return [...personas].sort(
            (a, b) =>
                (a.createdAt ? new Date(a.createdAt).getTime() : 0) -
                (b.createdAt ? new Date(b.createdAt).getTime() : 0),
        );
    };

    const fetchPersonas = useCallback(async () => {
        try {
            const accessToken = await getAccessToken();
            const fetchedPersonas = await personaService.GetPersonasAsync(accessToken);
            const account = instance.getActiveAccount();
            const currentUserId = account?.idTokenClaims?.oid;

            const mappedPersonas = fetchedPersonas.map((persona: IPersona) => ({
                ...persona,
                image: persona.imageUrl || undefined,
                isOwner: persona.userId === currentUserId,
                isReadOnly: persona.isReadOnly,
            }));

            // Sort the personas before returning
            return sortPersonas(mappedPersonas);
        } catch (error) {
            throw error;
        }
    }, [getAccessToken, instance]);

    const {
        data: personas,
        isLoading,
        error,
        refetch,
    } = useQuery({
        queryKey: ['personas'],
        queryFn: fetchPersonas,
    });

    return { personas, isLoading, error, refetchSessions: refetch };
};
