
import { makeStyles } from '@fluentui/react-components';
import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { CheckmarkRegular } from '@fluentui/react-icons';

const useClasses = makeStyles({
    codeBlockContainer: {
        position: 'relative',
        marginBottom: '1rem',
    },
    copyButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        // padding: '0.5rem',
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
        // border: 'none',
        color: 'white',
        zIndex: 10, // Ensure the button is above the code block
    },
    syntaxHighlighter: {
        // borderRadius: '0.5rem',
        backgroundColor: 'black !important', // Override background color
    },
    copiedButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        // padding: '0.5rem',
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
        // border: 'none',
        color: 'white',
        zIndex: 10, 
        // Additional styles for the copied button state if needed
    },
});

interface CodeBlockProps {
    language: string;
    value: string;
}


const CodeBlock: React.FC<CodeBlockProps> = ({ language, value }) => {
    const classes = useClasses();
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        copy(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000); // Reset the button after 3 seconds
    };

    return (
        <div className={classes.codeBlockContainer}>
            <button className={copied ? classes.copiedButton : classes.copyButton} onClick={handleCopy}>
                {copied ? (
                    <>
                        <CheckmarkRegular /> Copied
                    </>
                ) : (
                    'Copy'
                )}
            </button>
            <SyntaxHighlighter
                language={language}
                style={materialDark}
                customStyle={{ borderRadius: '0.5rem' }}
                className={classes.syntaxHighlighter}
            >
                {value}
            </SyntaxHighlighter>
        </div>
    );
};

export default CodeBlock;
