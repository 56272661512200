import { useQuery } from '@tanstack/react-query';
import { useAppDispatch } from '../../redux/app/hooks';
import { setChatSessions } from '../../redux/features/conversations/conversationsSlice';
import { useChat } from '../hooks';

export const useGetChatSessions = () => {
    const dispatch = useAppDispatch();
    const chat = useChat();

    const {
        data: chatSessions,
        isLoading,
        error,
        refetch
    } = useQuery({
        queryKey: ['chatSessions'],
        queryFn: async () => {
            const sessions = await chat.fetchChatSessions();
            dispatch(setChatSessions(sessions));
            return sessions;
        }
    });

    return { chatSessions, isLoading, error, refetchSessions: refetch };
};
