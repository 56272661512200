import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import React from 'react';
import Button from '../../shared/Button';

interface ConfirmDialogProps extends Omit<DialogProps, 'children'> {
    title: string;
    text: string;
    closeText?: string;
    okText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    loading?: boolean;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    title,
    text,
    closeText = 'Cancel',
    okText = 'Ok',
    onOk,
    onCancel,
    loading,
    ...dialogProps
}) => {
    return (
        <Dialog {...dialogProps}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent>{text}</DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={onCancel} disabled={loading}>
                                {closeText}
                            </Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={onOk} disabled={loading} loading={loading}>
                            {okText}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

export default ConfirmDialog;
