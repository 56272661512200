import { Card, LargeTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { styleConstants } from '../../../../libs/constants';
import PersonaAvatarView from '../PersonaAvatarView';
import { PersonaDocuments } from '../PersonaDocuments';
import PersonaPopoverTitle from '../PersonaPopoverTitle';
import { IPersonaSettings } from '../types';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.gap(tokens.spacingVerticalL),
        ...styleConstants.visibleScrollbarStyles,
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        maxHeight: '100px',
        ...shorthands.gap(tokens.spacingVerticalXXL),
    },
});

const personaDocumentsText = 'Persona documents are documents that the persona can reference for future conversations';

const PersonDocumentsSettings: React.FC<IPersonaSettings> = ({ persona, chatId }) => {
    const classes = useClasses();


    return (
        <Card>
            <div className={classes.container}>
                <div className={classes.header}>
                    <PersonaAvatarView imageUrl={persona?.imageUrl} />
                    <LargeTitle>{persona?.name}</LargeTitle>
                </div>
                <div>
                    <PersonaPopoverTitle
                        title="Persona Document:"
                        popoverText={personaDocumentsText}
                        style={{ marginTop: '20px' }}
                    />
                    {persona?.id && chatId && (
                        <PersonaDocuments
                            chatId={chatId}
                            personaId={persona.id}
                            userId={persona.userId}
                            // allowUpload={!!persona.isOwner && !persona.isReadOnly}
                            title=""
                            allowUpload
                            uploadedFiles={persona.uploadedFiles as Record<string, Array<{
                                url: string;
                                fileUploadedName: string;
                                fileExtension: string;
                            }>>} 
                        />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default PersonDocumentsSettings;
