/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
    Card,
    MenuItemRadio,
    MenuList,
    MenuProps,
    Text,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import React, { useMemo } from 'react';
import { useLocalStorage } from '../../../libs/hooks';
import PersonaAvatarView from './PersonaAvatarView';
import PersonDocumentsSettings from './PersonaSettings/PersonDocumentsSettings';
import PersonaAdvancedSettings from './PersonaSettings/PersonaAdvancedSettings';
import PersonaGeneralSettings from './PersonaSettings/PersonaGeneralSettings';
import PersonaSharingSettings from './PersonaSettings/PersonaSharingSettings';
import { IPersonaSettings, PersonaSettingsType } from './types';

const useClasses = makeStyles({
    container: {
        display: 'grid',
        gridTemplateColumns: '250px 1fr',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding(tokens.spacingHorizontalNone),
        ...shorthands.gap('0'),
    },
    manageCard: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalNone),
    },
    manageItem: {
        maxWidth: '100%',
        '& > .fui-MenuItem__checkmark': {
            display: 'none',
        },
        '& > .fui-MenuItem__content': {
            display: 'flex',
            justifyContent: 'space-between',
            ...shorthands.margin(0, tokens.spacingHorizontalS),
        },
    },
    activeManageItem: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
        color: tokens.colorNeutralForeground2Hover,
    },
    personaName: {
        flexGrow: '0 !important',
        ...shorthands.margin('0'),
        ...typographyStyles.subtitle2,
    },
    settingsList: {
        width: '100%',
    },
});

const settingTypesMap: Record<PersonaSettingsType, PersonaSettingsType> = {
    general: 'general',
    advanced: 'advanced',
    sharing: 'sharing',
    documents: 'documents',
};

const settingsTypesArray = Object.values(settingTypesMap);

const PersonaManagement: React.FC<IPersonaSettings> = ({
    persona,
    handleSavePersona,
    handleDeletePersona,
    handleConfigurationChange,
    generalSettings,
    chatId,
    handleShare,
    handleUnshare,
    sharedPersonas,
    isDeleting,
    isSetting,
    isSaving
}) => {
    const classes = useClasses();
    const { value: checkedValues, setValue: setCheckedValues } = useLocalStorage<Record<string, PersonaSettingsType[]>>("personaCheckedValues",{
        management: ['general'],
    });
    const currentSettings = checkedValues.management?.[0];

    const onChange: MenuProps['onCheckedValueChange'] = (_, { name, checkedItems }) => {
        setCheckedValues((s) => ({ ...s, [name]: checkedItems as PersonaSettingsType[] }));
    };

    const settingsMap = useMemo(() => {
        return {
            general: (
                <PersonaGeneralSettings
                    persona={persona}
                    handleSavePersona={handleSavePersona}
                    handleDeletePersona={handleDeletePersona}
                    handleConfigurationChange={handleConfigurationChange}
                    isDeleting={isDeleting}
                    isSetting={isSetting}
                    isSaving={isSaving}
                />
            ),
            sharing: (
                <PersonaSharingSettings
                    persona={persona}
                    handleShare={handleShare}
                    handleUnshare={handleUnshare}
                    sharedPersonas={sharedPersonas}
                    isDeleting={isDeleting}
                    isSetting={isSetting}
                    isSaving={isSaving}
                />
            ),
            advanced: (
                <PersonaAdvancedSettings
                    handleSavePersona={handleSavePersona}
                    handleConfigurationChange={handleConfigurationChange}
                    generalSettings={generalSettings}
                    persona={persona}
                    isDeleting={isDeleting}
                    isSetting={isSetting}
                    isSaving={isSaving}
                />
            ),
            documents: (
                <PersonDocumentsSettings
                    persona={persona}
                    chatId={chatId}
                    isDeleting={isDeleting}
                    isSetting={isSetting}
                />
            ),
        };
    }, [
        persona,
        handleSavePersona,
        handleDeletePersona,
        handleConfigurationChange,
        handleShare,
        handleUnshare,
        sharedPersonas,
        generalSettings,
        chatId,
        isDeleting,
        isSetting,
        isSaving
    ]);

    return (
        <Card className={classes.container} appearance="filled-alternative">
            <Card className={classes.manageCard}>
                <Text className={classes.personaName}>{persona?.name}</Text>
                <PersonaAvatarView imageUrl={persona?.imageUrl} />
                <MenuList
                    checkedValues={checkedValues}
                    onCheckedValueChange={onChange}
                    className={classes.settingsList}
                >
                    {settingsTypesArray.map((type) => (
                        <MenuItemRadio
                            key={type}
                            className={mergeClasses(
                                classes.manageItem,
                                currentSettings === type && classes.activeManageItem,
                            )}
                            value={type}
                            name="management"
                        >
                            <span>
                                <span style={{ textTransform: 'capitalize' }}>{type}</span> settings
                            </span>
                        </MenuItemRadio>
                    ))}
                </MenuList>
            </Card>
            {settingsMap[currentSettings]}
        </Card>
    );
};

export default PersonaManagement;
