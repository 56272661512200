/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { setConversations } from '../../redux/features/conversations/conversationsSlice';
import { Conversations } from '../../redux/features/conversations/ConversationsState';
import { useChat } from '../hooks/useChat';
import { IChatMessage } from '../models/ChatMessage';
import { IChatParticipant, IChatSession } from '../models/ChatSession';
import { IChatUser } from '../models/ChatUser';

export interface ConversationResult {
    messages: IChatMessage[];
    chatUsers: IChatUser[];
}


export const useGetMessages = (chatSessions: IChatSession[], sessionId?: string, initialLimit = 80) => {
    const { features } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();

    const chat = useChat();

    const activeSession = useMemo(() => {
        return chatSessions.find((session) => session.id === sessionId);
    }, [chatSessions, sessionId]);

       const {
           data,
           isLoading,
           error,
           refetch,
       } = useQuery({
           queryKey: ['messages', sessionId],
           queryFn: async () => fetchMessages(0, initialLimit),
       });


    const fetchMessages = useCallback(
        async (newOffset: number, limit: number) => {
            if (!sessionId ) {
                return null;
            }
            try {
                const result = await chat.fetchChatMessages(sessionId, newOffset, limit);
                if (!result) {
                    return null;
                }

                return result;
            } catch (e: any) {
                return null;
            }
        },
        [sessionId, chat],
    );


    const handleSetConversations = useCallback(
        () => {
            if (!sessionId || !activeSession || !data) {
                return;
            }
            const loadedConversations: Conversations = {};

            // Populate loaded conversations
            loadedConversations[sessionId] = {
                id: activeSession.id,
                title: activeSession.title,
                systemDescription: activeSession.systemDescription,
                memoryBalance: activeSession.memoryBalance,
                users: data.chatUsers,
                messages: data.messages,
                enabledHostedPlugins: activeSession.enabledPlugins,
                botProfilePicture:
                    activeSession.personaImage && activeSession.personaImage !== ''
                        ? activeSession?.personaImage
                        : chat.getBotProfilePictureV2(sessionId),
                input: '',
                botResponseStatus: undefined,
                userDataLoaded: false,
                participantsUsers: activeSession?.users as IChatParticipant[],
                disabled: false,
                hidden: !features[FeatureKeys.MultiUserChat].enabled && data.chatUsers.length > 1,
                selectedPersonaId: activeSession.selectedPersonaId,
                personaName: activeSession.personaName,
                responseTemperature: activeSession.responseTemperature,
                responseTopP: activeSession.responseTopP,
                responsePresencePenalty: activeSession.responsePresencePenalty,
                responseFrequencyPenalty: activeSession.responseFrequencyPenalty,
                intentTemperature: activeSession.intentTemperature,
                intentTopP: activeSession.intentTopP,
                intentPresencePenalty: activeSession.intentPresencePenalty,
                intentFrequencyPenalty: activeSession.intentFrequencyPenalty,
                imageUrl: activeSession.imageUrl ?? null,
                gptModel: activeSession.gptModel,
                lastMessageTimestamp: activeSession.lastMessageTimestamp,
                chatSessionTotalTokens: activeSession.chatSessionTotalTokens,
                remainingTokens: activeSession.remainingTokens,
            };

            dispatch(setConversations(loadedConversations));
        },
        [activeSession, sessionId, chat, features, dispatch],
    );


    useEffect(() => {
        if (data) {
            handleSetConversations();
        }
    }, [data, chatSessions]);


    return {
        conversation: data,
        isLoading,
        error,
        refetch,
    };
};
