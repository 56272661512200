/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogSurface, DialogTitle,
    DialogTrigger,
    Input,
    LargeTitle,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles,
    shorthands,
    tokens
} from '@fluentui/react-components';
import { useEffect, useRef, useState } from 'react';
import { useLoading } from '../../../../hooks';
import { styleConstants } from '../../../../libs/constants';
import { useGraph } from '../../../../libs/hooks';
import { UserSchema } from '../../../../libs/hooks/useGraph';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import Button from '../../../shared/Button';
import { emailValidationCheck } from '../../../utils/PluginUtils';
import PersonaAvatarView from '../PersonaAvatarView';
import PersonaPopoverTitle from '../PersonaPopoverTitle';
import { IPersonaSettings } from '../types';


const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.gap(tokens.spacingVerticalL),
        ...styleConstants.visibleScrollbarStyles,
    },
    horizontal: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingVerticalSNudge),
        alignItems: 'center',
    },
    dialog: {
        maxWidth: '25%',
    },
    header: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        alignItems: 'center',
        maxHeight: '120px',
        ...shorthands.gap(tokens.spacingVerticalXXL),
    },
    root: {
        width: '450px',
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    tableHeader: {
        fontWeight: tokens.fontSizeBase600,
    },
});



const PersonaSharingSettings: React.FC<IPersonaSettings> = ({
    persona,
    handleShare,
    handleUnshare,
    sharedPersonas,
    isDeleting,
}) => {
    const classes = useClasses();
    const [email, setEmail] = useState('');
    const { loading, handleLoading } = useLoading();
    const { loading: deleting, handleLoading: handleDeleteLoading } = useLoading();
    // const [ownerName, setOwnerName] = useState<string | null>(null); // New state for storing owner's name
    const [ownerDisplayName, setOwnerDisplayName] = useState<string>('');
    const deletingUserId = useRef<string | undefined>('');
    const { fetchUsers, findUserById } = useGraph();
    const [suggestions, setSuggestions] = useState<UserSchema[]>([]);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const loggedInUserId = activeUserInfo?.id?.split?.('.')?.[0];
    const isOwner = persona?.userId === loggedInUserId;
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    useEffect(() => {
        const fetchOwnerDisplayName = async () => {
            if (persona?.userId) {
                try {
                    const owner = await findUserById(persona.userId);
                    setOwnerDisplayName(owner?.displayName ?? 'Unknown User');
                } catch (error) {
                    console.error('Error fetching owner display name:', error);
                    setOwnerDisplayName('Unknown User');
                }
            }
        };

        void fetchOwnerDisplayName();
    }, [persona?.userId, findUserById]);

    useEffect(() => {
        const delayDebounceFn: NodeJS.Timeout = setTimeout(() => {
            if (email.length > 2) {
                fetchUsers(email)
                    .then((results) => {
                        setSuggestions(results);
                    })
                    .catch((error) => {
                        console.error('Error fetching users:', error);
                    });
            } else {
                setSuggestions([]);
            }
        }, 1000);

        return () => {
            clearTimeout(delayDebounceFn);
        };
    }, [email, fetchUsers]);

    const handleConfirmShare = () => {
        setConfirmDialogOpen(false);
        if (!handleShare) {
            return;
        }
        handleLoading(true);
        handleShare(email);
        handleLoading(false);
        setEmail('');
        setSuggestions([]);
    };

    const onShare = () => {
        if (!handleShare) {
            return;
        }

        if (persona?.hasUploadedFile) {
            setConfirmDialogOpen(true);
        } else {
            handleConfirmShare();
        }
    };

    const onUnshare = (email: string) => {
        if (!handleUnshare) {
            return;
        }

        handleDeleteLoading(true);
        deletingUserId.current = email;
        handleUnshare(email);
        deletingUserId.current = '';
        handleDeleteLoading(false);
    };

    return (
        <Card>
            <div className={classes.container}>
                <Dialog
                    open={confirmDialogOpen}
                    onOpenChange={(_, data) => {
                        setConfirmDialogOpen(data.open);
                    }}
                >
                    <DialogSurface className={classes.root}>
                        <DialogTitle>Are you sure you want to share persona: </DialogTitle>
                        <DialogTitle>{persona?.name}?</DialogTitle>
                        <DialogContent>
                            Sharing this persona will also share all linked documents.
                            <div> Are you sure you want to proceed?</div>
                        </DialogContent>
                        <div style={{ marginTop: '10px', marginBottom: '15px' }}></div>

                        <DialogActions style={{ justifyContent: 'flex-end' }}>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button
                                    appearance="secondary"
                                    onClick={() => {
                                        setConfirmDialogOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger action="close" disableButtonEnhancement>
                                <Button
                                    appearance="primary"
                                    onClick={() => {
                                        handleConfirmShare();
                                    }}
                                >
                                    Proceed
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogSurface>
                </Dialog>

                <div className={classes.header}>
                    <PersonaAvatarView imageUrl={persona?.imageUrl} />
                    <LargeTitle>{persona?.name}</LargeTitle>
                </div>
                <PersonaPopoverTitle title="Sharing:" popoverText="The prompt that defines the chat bot's persona." />
                <div>Created By: {ownerDisplayName}</div>

                <div className={classes.horizontal}>
                    <Input
                        type="text"
                        value={email}
                        disabled={!isOwner}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        placeholder="Enter at least 3 characters for suggestions"
                        style={{ width: '310px' }}
                    />
                    <Button
                        style={{ marginRight: '5px' }}
                        disabled={loading || !email || !emailValidationCheck(email) || !isOwner}
                        onClick={() => {
                            onShare();
                        }}
                        loading={loading}
                    >
                        Share Persona
                    </Button>
                </div>

                {suggestions.length > 0 && (
                    <div>
                        {suggestions.map((user) => (
                            <div
                                key={user.id}
                                onClick={() => {
                                    if (user.mail) {
                                        setEmail(user.mail);
                                        setSuggestions([]);
                                    }
                                }}
                            >
                                {user.mail ?? ''}
                            </div>
                        ))}
                    </div>
                )}

                <Table className={classes.table}>
                    <TableHeader className={classes.tableHeader}>
                        <TableRow>
                            <TableHeaderCell className={classes.tableHeader}>Email</TableHeaderCell>
                            <TableHeaderCell className={classes.tableHeader}>Actions</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {!!sharedPersonas?.length &&
                            sharedPersonas.map((user) => (
                                <TableRow key={user?.id}>
                                    <TableCell>
                                        <TableCellLayout>
                                            <strong>{user?.displayName}</strong>
                                        </TableCellLayout>
                                        <TableCellLayout>{user?.mail}</TableCellLayout>
                                    </TableCell>
                                    <TableCell>
                                        <TableCellLayout>
                                            <Button
                                                onClick={() => {
                                                    user?.mail && onUnshare(user.mail);
                                                }}
                                                disabled={!isOwner}
                                                loading={
                                                    (deleting && deletingUserId.current === user?.mail) || isDeleting
                                                }
                                            >
                                                Unshare Persona
                                            </Button>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {!sharedPersonas?.length && (
                            <TableRow>
                                <TableCell>
                                    <TableCellLayout>No data</TableCellLayout>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
        </Card>
    );
};

export default PersonaSharingSettings;
