import { Label, Slider, makeStyles } from '@fluentui/react-components';

import React from 'react';
import PersonaPopoverTitle from './PersonaPopoverTitle';
import { IPersonaSettings } from './types';

interface PersonaSettingsSliderProps extends IPersonaSettings {
    popoverText: string;
    label: string;
    disabled?: boolean;
    name: keyof NonNullable<IPersonaSettings['generalSettings']>;
}

const useClasses = makeStyles({
    container: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '500px',
    },
    label: {
        width: '100%',
        whiteSpace: 'nowrap',
    },
});

const PersonaSettingsSlider: React.FC<PersonaSettingsSliderProps> = ({
    generalSettings,
    handleConfigurationChange,
    popoverText,
    label,
    disabled,
    name,
}) => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <Label className={classes.label}>
                {label}: <span>{generalSettings?.[name]}</span>
            </Label>
            <Slider
                min={0}
                max={1}
                step={0.1}
                value={generalSettings?.[name]}
                disabled={disabled}
                onChange={(e) => {
                    handleConfigurationChange?.(name, Number(e.target.value));
                }}
            />
            <PersonaPopoverTitle popoverText={popoverText} />
        </div>
    );
};

export default PersonaSettingsSlider;
