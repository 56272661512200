import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import * as utils from './../../utils/TextUtils';

// const InitialMSG = "Hello I'm Your Chat Conductor, How Can You I Help You Today?"

export interface ChatHistoryTextContentProps {
    message: IChatMessage;
    isInitialMessage: boolean;
    initialChat: string; // Add this line
}

const useClasses = makeStyles({
    content: {
        wordBreak: 'break-word',
    },
    initialContent: {
        wordBreak: 'break-word',
        fontWeight: 'bold',
    },
});


export const ChatHistoryTextContent: React.FC<ChatHistoryTextContentProps> = ({ message, isInitialMessage,initialChat}) => {
    const classes = useClasses();
    
    const content = utils.replaceCitationLinksWithIndices(utils.formatChatTextContent(message.content), message);

     return (
         <div className={isInitialMessage ? classes.initialContent : classes.content}>
             <ReactMarkdown remarkPlugins={[remarkGfm]}>{isInitialMessage ? initialChat
                 : content}</ReactMarkdown>
         </div>
     );
};
