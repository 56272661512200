import { useMsal } from '@azure/msal-react';
import { Button, Divider, Label, makeStyles, tokens } from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import { AuthHelper } from '../../../libs/auth/AuthHelper';
import { BrandingSettingsService } from '../../../libs/services/BrandingSettingsService'; // Import BrandingSettingsService
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { BrandingSettings } from '../../../redux/features/app/AppState';

const useStyles = makeStyles({
    feature: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputContainer: {
        marginBottom: tokens.spacingVerticalS, // Adjust this value as needed
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    featureDescription: {
        paddingLeft: '5%',
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    errorMessage: {
        color: 'red',
    },
    logoIcon: {
        width: '32px', // Adjust as needed
        height: '32px', // Adjust as needed
        // marginLeft: '5px', // Adjust as needed
    },
});

interface BrandingSectionProps {
    brandingSettings: BrandingSettings;
    onBrandingChange: (key: keyof BrandingSettings, value: string) => void;
}

export const BrandingSection: React.FC<BrandingSectionProps> = ({ brandingSettings, onBrandingChange }) => {
    const classes = useStyles();
    const localBrandingSettings = useAppSelector((state: RootState) => state.app.brandingSettings);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [titleError, setTitleError] = useState<boolean>(false);
    const [botNameError, setBotNameError] = useState<boolean>(false);
    const [logoIconValid, setLogoIconValid] = useState<boolean>(true);
    const [saveClicked, setSaveClicked] = useState<boolean>(false);
    const [logoErrorMessage, setLogoErrorMessage] = useState<string>('');

    const { inProgress, instance } = useMsal();

    const brandingSettingsService = useMemo(() => new BrandingSettingsService(), []);

    // Check if the environment variable is set and has a truthy value
    const isDisabled = process.env.REACT_APP_DISABLE_BRANDING_FEATURES === 'True';

    useEffect(() => {
        const customHeaderColor = localStorage.getItem('customHeaderColor');
        if (customHeaderColor) {
            onBrandingChange('headerBarColor', customHeaderColor);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBrandingChange = (key: keyof BrandingSettings, value: string) => {
        // console.log(process.env.REACT_APP_DISABLED_BRANDING_FEATURES);
        // Set the disabled property based on the value of the environment variable
        if (
            key === 'title' ||
            key === 'loginHeaderTitle' ||
            key === 'loginBTNName' ||
            key === 'configureInitialMSG' ||
            key === 'loginBTNLogo' ||
            key === 'loginHeaderTitleColor'||
            key == 'botName'
        ) {
            //onBrandingChange(key, { ...brandingSettings[key], disabled: isDisabled });
            if (brandingSettings[key]) {
                // onBrandingChange(key, { ...brandingSettings[key], disabled: isDisabled });
                const updatedValue = Object.assign({}, brandingSettings[key], { disabled: isDisabled });
                onBrandingChange(key, updatedValue);
            }
        }

        onBrandingChange(key, value);
        setUnsavedChanges(true);
        if (key === 'title' && value === '') {
            setTitleError(true);
        } else {
            setTitleError(false);
        }
        if (key === 'loginHeaderTitle' && value === '') {
            setTitleError(true);
        } else {
            setTitleError(false);
        }

        if (key === 'configureInitialMSG' && value === '') {
            setTitleError(true);
        } else {
            setTitleError(false);
        }

        if (key === 'loginBTNName' && value === '') {
            setTitleError(true);
        } else {
            setTitleError(false);
        }
        if (key === 'logoIconLink') {
            validateLogoIconURL(value);
        }

        if (key === 'loginBTNLogo') {
            validateLogoIconURL(value);
        }
        if (key === 'titleFontColor' && value === '') {
            brandingSettings.titleFontColor = '#ffffff';
        }

        if (key === 'loginHeaderTitleColor' && value === '') {
            brandingSettings.loginHeaderTitleColor = '#ffffff';
        }
        if(key==='botName' && value === ''){
            setBotNameError(true)
        }else{
            setBotNameError(false)
        }

        if (key === 'loginBTNTextColor' && value === '') {
            brandingSettings.loginBTNTextColor = '#ffffff';
        }

        if (key === 'loginBTNColor' && value === '') {
            brandingSettings.loginBTNColor = '#007bff';
        }

        if (key === 'headerBarColor') {
            localStorage.setItem('customHeaderColor', value);
        }
    };

    const validateLogoIconURL = (url: string) => {
        if (url === '') {
            setLogoIconValid(true);
            setLogoErrorMessage('');
            return;
        }
        if (!url) {
            // Empty URL
            setLogoIconValid(true);
            setLogoErrorMessage('');
            return;
        }

        const img = new Image();
        img.onload = () => {
            // Valid URL
            setLogoIconValid(true);
            setLogoErrorMessage('');
        };
        img.onerror = () => {
            // Invalid URL
            setLogoIconValid(false);
            setLogoErrorMessage('Invalid URL for logo icon.');
        };
        img.src = url;
    };

    const handleSave = async () => {
        const updatedSettings = { ...localBrandingSettings };
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        // console.log('accessToken', accessToken);
        // console.log('updatedSettings:', updatedSettings);
        try {
            await brandingSettingsService.updateBrandingSettingsAsync(
                updatedSettings,
                accessToken,
                brandingSettings.Id || 'default',
            );
            setUnsavedChanges(false);
            setSaveClicked(true);
        } catch (error) {
            // Handle error (e.g., show an error message to the user)
        }
    };

    const saveChanges = async () => {
        if (localBrandingSettings.title === '') {
            setTitleError(true);
            return;
        }
        if(localBrandingSettings.botName===''){
            setBotNameError(true);
            return;
        }

        if (localBrandingSettings.loginHeaderTitle === '') {
            setTitleError(true);
            return;
        }

        if (localBrandingSettings.configureInitialMSG === '') {
            setTitleError(true);
            return;
        }

        if (localBrandingSettings.loginBTNName === '') {
            setTitleError(true);
            return;
        }

        if (localBrandingSettings.logoIconLink === '') {
            await handleSave();
            return;
        }

        if (localBrandingSettings.loginBTNLogo === '') {
            await handleSave();
            return;
        }

        const img = new Image();
        img.onload = async () => {
            await handleSave();
            setLogoIconValid(true);
        };
        img.onerror = () => {
            setLogoIconValid(false);
            setLogoErrorMessage('Invalid URL for logo icon.');
        };
        img.src = localBrandingSettings.logoIconLink;
        return handleSave();
    };

    return (
        <>
            <Divider />
            <div className={classes.feature}>
                <h4>Header Bar Customization</h4>

                {/* Header Title  */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Title</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.title}
                        onChange={(e) => {
                            handleBrandingChange('title', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <input
                        type="color"
                        value={brandingSettings.titleFontColor}
                        onChange={(e) => {
                            const colorValue = e.target.value;
                            handleBrandingChange('titleFontColor', colorValue);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {titleError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Title cannot be empty</span>
                    </div>
                )}
                {/* BotName   */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>BotName</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.botName}
                        onChange={(e) => {
                            handleBrandingChange('botName', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {botNameError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Bot name cannot be empty</span>
                    </div>
                )}
                {/* Header Logo  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Logo</Label>
                    <input
                        type="text"
                        style={{ width: '320px', marginRight: '4%' }}
                        value={localBrandingSettings.logoIconLink}
                        onChange={(e) => {
                            handleBrandingChange('logoIconLink', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <div>
                        {saveClicked ||
                            (localBrandingSettings.logoIconLink !== '' && (
                                <img
                                    className={classes.logoIcon}
                                    style={{ maxWidth: '32px', maxHeight: '32px', width: 'auto', height: 'auto' }}
                                    src={localBrandingSettings.logoIconLink}
                                />
                            ))}
                    </div>
                </div>
                {!logoIconValid && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>{logoErrorMessage}</span>
                    </div>
                )}
                {/* Background Img  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Background</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={localBrandingSettings.backgroundImage}
                        onChange={(e) => {
                            handleBrandingChange('backgroundImage', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    {localBrandingSettings.backgroundImage !== '' && (
                        <div style={{ width: '34px', height: '28px' }}>
                            <img
                                className={classes.logoIcon}
                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                src={localBrandingSettings.backgroundImage}
                            />
                        </div>
                    )}
                </div>
                {/* HeaderBar  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Header Bar Color</Label>
                    <input
                        type="color"
                        value={localBrandingSettings.headerBarColor}
                        onChange={(e) => {
                            if (localBrandingSettings.headerBarColor !== '') {
                                localStorage.setItem('customHeaderColor', e.target.value);
                            }
                            handleBrandingChange('headerBarColor', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                </div>

                <h4>Login Page Customization</h4>

                {/* Login Title  */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login Header</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.loginHeaderTitle}
                        onChange={(e) => {
                            handleBrandingChange('loginHeaderTitle', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <input
                        type="color"
                        value={brandingSettings.loginHeaderTitleColor}
                        onChange={(e) => {
                            const colorValue = e.target.value;
                            handleBrandingChange('loginHeaderTitleColor', colorValue);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {titleError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Title cannot be empty</span>
                    </div>
                )}
                {/* Login Body  */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login Body</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.loginBody}
                        onChange={(e) => {
                            handleBrandingChange('loginBody', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <input
                        type="color"
                        value={brandingSettings.loginBodyColor}
                        onChange={(e) => {
                            const colorValue = e.target.value;
                            handleBrandingChange('loginBodyColor', colorValue);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {titleError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Title cannot be empty</span>
                    </div>
                )}
                {/* login Div  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login Container</Label>
                    <input
                        type="color"
                        value={localBrandingSettings.loginContainerColor}
                        onChange={(e) => {
                            if (localBrandingSettings.loginContainerColor !== '') {
                                localStorage.setItem('loginContainerColor', e.target.value);
                            }
                            handleBrandingChange('loginContainerColor', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {/* Loign BTN Changes  */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login Button</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.loginBTNName}
                        onChange={(e) => {
                            handleBrandingChange('loginBTNName', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <input
                        type="color"
                        value={brandingSettings.loginBTNTextColor}
                        onChange={(e) => {
                            const colorValue = e.target.value;
                            handleBrandingChange('loginBTNTextColor', colorValue);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {titleError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Title cannot be empty</span>
                    </div>
                )}
                {/* login btn bg  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login Button Color</Label>
                    <input
                        type="color"
                        value={localBrandingSettings.loginBTNColor}
                        onChange={(e) => {
                            if (localBrandingSettings.loginBTNColor !== '') {
                                localStorage.setItem('loginBTNColor', e.target.value);
                            }
                            handleBrandingChange('loginBTNColor', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {/* login BTN Logo  */}
                <div className={classes.inputContainer} style={{ marginTop: '4%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Login BTN Logo</Label>
                    <input
                        type="text"
                        style={{ width: '320px', marginRight: '4%' }}
                        value={localBrandingSettings.loginBTNLogo}
                        onChange={(e) => {
                            handleBrandingChange('loginBTNLogo', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                    <div>
                        {saveClicked ||
                            (localBrandingSettings.loginBTNLogo !== '' && (
                                <img
                                    className={classes.logoIcon}
                                    style={{ maxWidth: '32px', maxHeight: '32px', width: 'auto', height: 'auto' }}
                                    src={localBrandingSettings.loginBTNLogo}
                                />
                            ))}
                    </div>
                </div>
                {!logoIconValid && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>{logoErrorMessage}</span>
                    </div>
                )}

                {/* Configurable chat starting message */}
                <h4>Configure Initial Message</h4>

                {/* CONFIGURE INITIAL MESSAGE  */}
                <div className={classes.inputContainer} style={{ marginTop: '5%' }}>
                    <Label style={{ fontWeight: 'bold', marginRight: '5%' }}>Configure Initial MSG</Label>
                    <input
                        type="text"
                        style={{ width: '300px', marginRight: '4%' }}
                        value={brandingSettings.configureInitialMSG}
                        onChange={(e) => {
                            handleBrandingChange('configureInitialMSG', e.target.value);
                        }}
                        disabled={isDisabled}
                    />
                </div>
                {titleError && (
                    <div className={classes.errorMessage} style={{ marginLeft: '11%' }}>
                        <span className={classes.errorMessage}>Title cannot be empty</span>
                    </div>
                )}

                <Button
                    disabled={!unsavedChanges}
                    onClick={() => {
                        void saveChanges();
                    }}
                >
                    Save
                </Button>
            </div>
        </>
    );
};
