import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthenticatedLayout, { ChatProps } from '../../components/layout/app';
import ChatView from '../../components/layout/chat';
import { useGetChatSessions } from '../../libs/api';
import Documents from '../../pages/core/Documents';
import Messages from '../../pages/core/Messages';
import Personas from '../../pages/core/Personas';




const AuthenticatedRoutes = ({ appState, classes, setAppState, initialBrandingSettings }: ChatProps) => {
     const [isCollapsed, setIsCollapsed] = useState(false);
     const { isLoading } = useGetChatSessions();


      const toggleCollapse = () => {
          setIsCollapsed(!isCollapsed);
      };
    // if (appState === AppState.ProbeForBackend) return <Loading text="Checking backend..." />;
    // if (appState === AppState.SettingUserInfo) return <Loading text="Setting user info..." />;
    // if (appState === AppState.ErrorLoadingUserInfo) return <Error text="Error loading user info" />;
    // if (appState === AppState.ErrorLoadingChats) return <Error text="Error loading chats" />;
    // if (appState === AppState.LoadingChats) return <Loading text="Loading chats..." />;


    return (
        <Routes>
            <Route
                path="/"
                element={
                    <AuthenticatedLayout
                        classes={classes}
                        appState={appState}
                        setAppState={setAppState}
                        initialBrandingSettings={initialBrandingSettings}
                        isCollapsed={isCollapsed}
                        toggleCollapse={toggleCollapse}
                        isLoading={isLoading}
                    />
                }
            >
                <Route index element={<Navigate to="/chat" replace />} />
                <Route path="chat/:sessionId" element={<ChatView />}>
                    <Route index element={<Navigate to="messages" replace />} />
                    <Route
                        path="messages"
                        element={<Messages initialMSG={initialBrandingSettings.configureInitialMSG} />}
                    />
                    <Route path="documents" element={<Documents />} />
                    <Route path="persona" element={<Personas />} />
                </Route>
                <Route path="*" element={<Navigate to="/chat" replace />} />
            </Route>
        </Routes>
    );
};


export default AuthenticatedRoutes;
