import * as React from 'react';
import { PersonaTab } from '../../components/chat/tabs/PersonaTab';


const Personas: React.FC = () => {

    return <PersonaTab   />;
};

export default Personas;
