/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { Delete16 } from '../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IPersonaDocumentDeleteConfirmationProps {
    isOwner?: boolean;
    onDeleteDocument: () => Promise<void>;
}



export const DocumentDeleteConfirmation: React.FC<IPersonaDocumentDeleteConfirmationProps> = ({
    isOwner,
    onDeleteDocument,
}) => {
    const classes = useClasses();


    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete document'} relationship="label">
                    <Button disabled={isOwner} icon={<Delete16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>You are about to delete this file. This action cannot be undone</DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="primary" onClick={onDeleteDocument}>
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
