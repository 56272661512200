/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
    Button,
    Card,
    Menu,
    MenuItem,
    MenuItemRadio,
    MenuList,
    MenuPopover,
    MenuProps,
    MenuTrigger,
    Spinner,
    Text,
    ToolbarButton,
    Tooltip,
    makeStyles,
    mergeClasses,
    shorthands,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import { CheckboxCheckedRegular, MoreHorizontal24Filled, PeopleCommunityFilled, PersonAddFilled } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { useLoading } from '../../../hooks';
import { IPersona } from '../../../libs/models/Persona';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import ConfirmDialog from '../shared/ConfirmDialog';
import PersonaCreate from './PersonaCreate';

interface PersonaListProps {
    personas: IPersona[];
    onPersonaSelect: (id: IPersona['id']) => void;
    onPersonaManage: (id: IPersona['id']) => void;
    handleSetPersona: (id: IPersona['id']) => void;
    handleDisablePersona: (id: IPersona['id']) => void;
    handleDeletePersona: (id: IPersona['id']) => void;
    isDeleting: boolean;
    isSetting: boolean;
    selectedPersonaId?: IPersona['id'];
    handleSavePersona: (newImageUrl?: IPersona['imageUrl'], newPersona?: IPersona) => void;
    activePersonaId: IPersona['id'];
}

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding('0'),
    },
    sectionTitle: {
        ...typographyStyles.subtitle2,
    },
    personaItem: {
        maxWidth: '100%',
        '& > .fui-MenuItem__checkmark': {
            display: 'none',
        },
        '& > .fui-MenuItem__content': {
            display: 'flex',
            justifyContent: 'space-between',
            ...shorthands.margin(0, tokens.spacingHorizontalS),
        },
    },
    activeItem: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
        color: tokens.colorNeutralForeground2Hover,
    },
    titleWrapper: {
        ...shorthands.margin(0, tokens.spacingHorizontalL),
        flexGrow: '0 !important',
        marginTop: tokens.spacingVerticalL,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    personaNameContainer: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    sharedBadge: {
        backgroundColor: tokens.colorBrandBackground,
        color: tokens.colorNeutralForegroundOnBrand,
        fontSize: '10px',
        lineHeight: '14px',
        ...shorthands.padding('2px', '6px'),
        ...shorthands.borderRadius(tokens.borderRadiusCircular),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('4px'),
    },
});

const PersonaList: React.FC<PersonaListProps> = ({
    personas,
    onPersonaSelect,
    onPersonaManage,
    handleSetPersona,
    handleDeletePersona,
    selectedPersonaId,
    handleSavePersona,
    activePersonaId,
    isDeleting,
    isSetting,
}) => {
    const classes = useClasses();
    const [checkedValues, setCheckedValues] = React.useState<Record<string, string[]>>({ persona: [] });
    const [id, setId] = useState<IPersona['id'] | undefined>(undefined);
    const [openPersonaCreate, setOpenPersonaCreate] = useState(false);
    const { loading: deleting, handleLoading: handleDeleteLoading } = useLoading();

    const isPersonaActive = (id: IPersona['id']) => id === activePersonaId;

    const onChange: MenuProps['onCheckedValueChange'] = (_, { name, checkedItems }) => {
        setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
        onPersonaSelect(checkedItems[0]);
    };

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const loggedInUserId = activeUserInfo?.id.split('.')[0];


    const onDelete = () => {
        if (!id) {
            return;
        }
        handleDeleteLoading(true);
        handleDeletePersona(id);
        setId(undefined);
        handleDeleteLoading(false);
    };

       const SharedBadge = () => (
           <Tooltip content="Shared persona" relationship="label" positioning="above">
               <span className={classes.sharedBadge}>
                   <PeopleCommunityFilled fontSize={12} />
               </span>
           </Tooltip>
       );


    return (
        <Card className={classes.container}>
            <div className={classes.titleWrapper}>
                    <Button
                        icon={<PersonAddFilled />}
                        onClick={() => {
                            setOpenPersonaCreate(true);
                        }}
                    >
                        <Text className={classes.sectionTitle}>Create Persona</Text>
                    </Button>
            </div>
            <MenuList
                checkedValues={checkedValues}
                onCheckedValueChange={onChange}
                hasIcons={true}
                hasCheckmarks={true}
            >
                {personas.map((persona) => {
                    const isOwner = persona.userId === loggedInUserId;
                    const isShared = persona.sharedWithUsers.length > 0;

                    return (
                        <MenuItemRadio
                            key={persona.id}
                            value={persona.id}
                            name="persona"
                            className={mergeClasses(
                                classes.personaItem,
                                selectedPersonaId === persona.id && classes.activeItem,
                            )}
                            icon={
                                isPersonaActive(persona.id) ? (
                                    <CheckboxCheckedRegular style={{ marginTop: '5px' }} />
                                ) : undefined
                            }
                        >
                            <span>{persona.name}</span>
                            <Menu positioning="before-top" hoverDelay={100000000000000000}>
                                <MenuTrigger disableButtonEnhancement>
                                    {isDeleting && selectedPersonaId === persona.id ? (
                                        <Spinner size="extra-tiny" />
                                    ) : (
                                        <div className={classes.personaNameContainer}>
                                            {isShared && <SharedBadge />}
                                            <ToolbarButton aria-label="More" icon={<MoreHorizontal24Filled />} />
                                        </div>
                                    )}
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem
                                            onClick={() => {
                                                onPersonaManage(persona.id);
                                            }}
                                        >
                                            Manage Persona
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                handleSetPersona(persona.id);
                                            }}
                                            disabled={
                                                isSetting || isPersonaActive(persona.id) || activePersonaId
                                                    ? true
                                                    : false
                                            }
                                        >
                                            Set Persona
                                        </MenuItem>

                                        <MenuItem
                                            disabled={!isOwner}
                                            onClick={() => {
                                                setId(persona.id);
                                            }}
                                        >
                                            Delete Persona
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </MenuItemRadio>
                    );
                })}
            </MenuList>
            <ConfirmDialog
                open={Boolean(id)}
                modalType="alert"
                title="Are you sure?"
                text="You are about to delete this persona"
                okText="Delete"
                onOk={() => {
                    onDelete();
                }}
                onCancel={() => {
                    setId(undefined);
                }}
                loading={deleting || isDeleting}
            />
            <PersonaCreate
                open={openPersonaCreate}
                onCancel={() => {
                    setOpenPersonaCreate(false);
                }}
                onCreate={handleSavePersona}
            />
        </Card>
    );
};

export default PersonaList;
