/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useCallback, useEffect, useState } from 'react';

interface UseLocalStorageOptions<T> {
    serializer?: (value: T) => string;
    deserializer?: (value: string) => T;
}

export function useLocalStorage<T>(key: string, initialValue: T, options: UseLocalStorageOptions<T> = {}) {
    const serialize = options.serializer ?? JSON.stringify;
    const deserialize = options.deserializer ?? JSON.parse;

    const getInitialState = useCallback((): T => {
        try {
            const item = localStorage.getItem(key);
            if (item !== null) {
                return deserialize(item);
            }
            const serialized = serialize(initialValue);
            localStorage.setItem(key, serialized);
            return initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key "${key}":`, error);
            return initialValue;
        }
    }, [key, initialValue, serialize, deserialize]);

    const [storedValue, setStoredValue] = useState(() => getInitialState());

    const setValue = useCallback(
        (value: T | ((val: T) => T)) => {
            try {
                setStoredValue((prevValue) => {
                    const valueToStore = value instanceof Function ? value(prevValue) : value;
                    const serialized = serialize(valueToStore);
                    localStorage.setItem(key, serialized);
                    return valueToStore;
                });
            } catch (error) {
                console.warn(`Error setting localStorage key "${key}":`, error);
            }
        },
        [key, serialize],
    );

    const removeValue = useCallback(() => {
        try {
            localStorage.removeItem(key);
            setStoredValue(initialValue);
        } catch (error) {
            console.warn(`Error removing localStorage key "${key}":`, error);
        }
    }, [key, initialValue]);

    useEffect(() => {
        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === key && e.newValue !== null) {
                try {
                    const newValue = deserialize(e.newValue);
                    setStoredValue(newValue);
                } catch (error) {
                    console.warn(`Error parsing localStorage change for key "${key}":`, error);
                }
            } else if (e.key === key) {
                // If key was removed
                setStoredValue(initialValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => { window.removeEventListener('storage', handleStorageChange); };
    }, [key, initialValue, deserialize]);

    return {
        value: storedValue,
        setValue,
        removeValue,
    } as const;
}
