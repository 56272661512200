import { useMsal } from '@azure/msal-react';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    Label,
    Textarea,
    Toaster,
    makeStyles,
} from '@fluentui/react-components';
import { useState } from 'react';
import { useLoading } from '../../../hooks';
import { IPersona } from '../../../libs/models/Persona';
import { generateGUID } from '../../../libs/utils/general';
import Button from '../../shared/Button';
import { useNotify } from '../../shared/ToastHelper';

interface PersonaCreateProps extends Omit<DialogProps, 'children'> {
    onCreate?: (newImageUrl?: IPersona['imageUrl'], newPersona?: IPersona) => void;
    onCancel?: () => void;
}

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
});

const PersonaCreate: React.FC<PersonaCreateProps> = ({ onCreate, onCancel, ...dialogProps }) => {
    const { instance } = useMsal();
    const classes = useStyles();
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const { loading, handleLoading } = useLoading();
    const { notify, toasterId } = useNotify();

    const canSubmit = !!name && !!prompt;

    const handleSubmit =  () => {
        if (!onCreate) {
            return;
        }

        if (name && name.length > 64) {
           notify({ title: 'Input Error', body: 'Persona name cannot exceed 64 characters.', type: "warning" }); return;

        }

        const account = instance.getActiveAccount();
        const userId = account?.idTokenClaims?.oid;
        handleLoading(true);
         onCreate(undefined, {
            id: generateGUID(),
            name,
            prompt,
            imageUrl: '',
            sharedWithUsers: [],
            responseTemperature: 0.7,
            responseTopP: 1,
            responsePresencePenalty: 0.5,
            responseFrequencyPenalty: 0.5,
            intentTemperature: 0.7,
            intentTopP: 1,
            intentPresencePenalty: 0.5,
            intentFrequencyPenalty: 0.5,
            userId: userId ?? '',
        });
        handleLoading(false);
        onCancel && onCancel();
        setName('');
        setPrompt('');
    };

    return (
        <>
            <Toaster toasterId={toasterId} position="top-end" pauseOnHover />

            <Dialog {...dialogProps}>
                <DialogSurface>
                    <form
                        onSubmit={(event) => {
                            event.preventDefault();
                        }}
                    >
                        <DialogBody>
                            <DialogTitle>Create Persona</DialogTitle>
                            <DialogContent className={classes.content}>
                                <Label>Persona name:</Label>
                                <Input
                                    value={name}
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />
                                <Label>Persona prompt:</Label>
                                <Textarea
                                    value={prompt}
                                    onChange={(_, data) => {
                                        setPrompt(data.value);
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary" onClick={onCancel} disabled={loading}>
                                        Cancel
                                    </Button>
                                </DialogTrigger>
                                <Button
                                    type="submit"
                                    appearance="primary"
                                    onClick={ handleSubmit}
                                    disabled={!canSubmit || loading}
                                    loading={loading}
                                >
                                    <span>Create</span>
                                </Button>
                            </DialogActions>
                        </DialogBody>
                    </form>
                </DialogSurface>
            </Dialog>
        </>
    );
};

export default PersonaCreate;
