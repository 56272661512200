import { Toast as FLToast, ToastProps as FLToastProps, ToastBody, ToastTitle } from '@fluentui/react-components';
import React from 'react';

type ToastProps = FLToastProps & {
    title?: string;
    body?: string;
    subtitle?: string;
};

const Toast: React.FC<ToastProps> = ({
    title = 'This is a toast title',
    body = 'This is a toast body',
    subtitle,
    ...props
}) => {
    return (
        <FLToast {...props}>
            <ToastTitle>{title}</ToastTitle>
            <ToastBody subtitle={subtitle}>{body}</ToastBody>
        </FLToast>
    );
};

export default Toast;
