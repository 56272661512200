/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Button, Popover, PopoverSurface, PopoverTrigger, Text, makeStyles, shorthands, tokens, typographyStyles } from '@fluentui/react-components';
import React from 'react';
import { Info16 } from '../../shared/BundledIcons';

interface PersonaPopoverTitleProps {
    title?: string;
    popoverText: string;
    style?: React.CSSProperties
}

const useClasses = makeStyles({
    sectionTitle: {
        ...typographyStyles.body1Stronger,
        whiteSpace: 'nowrap',
    },
    horizontal: {
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    dialog: {
        maxWidth: '25%',
    },
});

const PersonaPopoverTitle: React.FC<PersonaPopoverTitleProps> = ({ title, popoverText, style }) => {
    const classes = useClasses();

    return (
        <div className={classes.horizontal} style={style}>
            {title && <Text className={classes.sectionTitle}>{title}</Text>}
            <Popover withArrow>
                <PopoverTrigger disableButtonEnhancement>
                    <Button icon={<Info16 />} appearance="transparent" />
                </PopoverTrigger>
                <PopoverSurface className={classes.dialog}>
                    {popoverText?.split?.('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
                </PopoverSurface>
            </Popover>
        </div>
    );
};

export default PersonaPopoverTitle;
