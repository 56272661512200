import { useQuery } from '@tanstack/react-query';
import { useGraph } from '../libs/hooks';

export const useUserAvatar = (activeUserId: string | undefined) => {
    const { getUserPhoto } = useGraph();

    const { data: userAvatarUrl, error } = useQuery({
        queryKey: ['userAvatar', activeUserId],
        queryFn: async () => {
            if (!activeUserId) return null;
            return await getUserPhoto(activeUserId);
        },
        enabled: !!activeUserId,
        staleTime: 5 * 60 * 1000, // 5 minutes

    });

    if (error) {
        console.error('Error in useUserAvatar:', error);
    }

    return userAvatarUrl;
};
