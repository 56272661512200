/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import { useChat } from '../../../libs/hooks';
import { IChatParticipant, IChatSession } from '../../../libs/models/ChatSession';
import { Breakpoints } from '../../../styles';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        paddingBottom: tokens.spacingVerticalXS,
    },
    header: {
        marginTop: 0,
        paddingBottom: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightRegular,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground3,
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

 interface IChatListSectionProps {
     header: string;
     sessions: IChatSession[];
     activeSessionId?: string;
 }

export const ChatListSection: React.FC<IChatListSectionProps> = ({ header, sessions, activeSessionId }) => {
    const classes = useClasses();
    const chat = useChat();

    return sessions.length > 0 ? (
        <div className={classes.root}>
            <Text className={classes.header}>{header}</Text>
            {sessions.map((session) => {
                const botProfilePicture =
                    session?.personaImage && session?.personaImage !== ''
                        ? session?.personaImage
                        : chat.getBotProfilePictureV2(session.id);

                const isChatShared = session.users && (session.users as IChatParticipant[]).length > 1 ? true : false;

                return (
                    <ChatListItem
                        key={session.id}
                        id={session.id}
                        isSelected={session.id === activeSessionId}
                        header={session.title}
                        timestamp={new Date(session.lastMessageTimestamp).getTime()}
                        preview="Click to view chat"
                        botProfilePicture={botProfilePicture}
                        isChatShared={isChatShared}
                    />
                );
            })}
        </div>
    ) : null;
};
