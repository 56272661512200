/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import {
    Button,
    makeStyles,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    shorthands,
    Spinner,
    tokens
} from '@fluentui/react-components';
import { Warning24Regular } from '@fluentui/react-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../../libs/hooks';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '65vw',
        marginLeft: '-50px',
    },
    content: {
        ...shorthands.padding('12px', '25px'),
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: tokens.colorStatusWarningBackground1,
        ...shorthands.border('1px', 'solid', tokens.colorStatusWarningBorder1),
        ...shorthands.borderRadius('4px'),
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        ...shorthands.gap('12px'),
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('4px'),
    },
    title: {
        fontWeight: 'bold',
        color: tokens.colorStatusWarningForeground1,
        ...shorthands.margin(0),
    },
    description: {
        color: tokens.colorStatusWarningForeground1,
        ...shorthands.margin(0),
        fontSize: '14px',
    },
    menuButton: {
        backgroundColor: tokens.colorStatusWarningBackground2,
        color: tokens.colorStatusWarningForeground1,
        '&:hover': {
            backgroundColor: tokens.colorStatusWarningBackground2,
            color: tokens.colorStatusWarningForeground1,
        },
    },
    icon: {
        color: tokens.colorStatusWarningForeground1,
    },
    menuIcon: {
        color: tokens.colorStatusWarningForeground1,
    },
});

const TokenUsageBanner = () => {
    const classes = useClasses();
    const chat = useChat();
    const { gptModelSettings } = useAppSelector((state: RootState) => state.app);
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();

    const onAddChat = async (gptModel: string) => {
        setIsLoading(true);
        try {
            const id = await chat.createChat(gptModel);
            setIsLoading(false);
            navigate(`/chat/${id}/messages`);
        } catch (error) {
            setIsLoading(false);
            console.error('Error creating chat:', error);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.messageContainer}>
                    <Warning24Regular className={classes.icon} />
                    <div className={classes.textContent}>
                        <h3 className={classes.title}>Token Usage Reached</h3>
                        <p className={classes.description}>
                            You have reached the token usage limit for this chat session. Please start a new chat to
                            continue your conversation.
                        </p>
                    </div>
                </div>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            data-testid="createNewConversationButton"
                            className={classes.menuButton}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner size="extra-tiny" /> : <>New Chat</>}
                        </Button>
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            {gptModelSettings.models.map((model, index) => (
                                <MenuItem
                                    key={index}
                                    data-testid="addNewBotMenuItem"
                                    onClick={async () => {
                                        await onAddChat(model);
                                    }}
                                >
                                    New Chat Session ({model})
                                </MenuItem>
                            ))}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        </div>
    );
};

export default TokenUsageBanner;
