import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ChatRoom } from '../../components/chat/ChatRoom';
import { Loading } from '../../components/views';
import { useUserAvatar } from '../../hooks';
import { useGetMessages } from '../../libs/api';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

interface Props {
    initialMSG: string;
}

const Messages: React.FC<Props> = ({ initialMSG,  }) => {
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { chatSessions } = useAppSelector((state: RootState) => state.conversations);
    const { sessionId } = useParams<{ sessionId?: string }>();

    const { isLoading } = useGetMessages(chatSessions, sessionId);

    const avatarUrl = useUserAvatar(activeUserInfo?.id);

    return (
        <>
            {isLoading ? (
                <Loading text="Fetching messages..." />
            ) : (
                <ChatRoom
                    initialMSG={initialMSG}
                    userPhotoUrl={avatarUrl ?? ""}
                    sessionId={sessionId ?? ""}
                />
            )}
        </>
    );
};

export default Messages;
