import { makeStyles, shorthands, Text, tokens, Tooltip } from '@fluentui/react-components';
import React, { FC } from 'react';

const useStyles = makeStyles({
    badge: {
        display: 'inline-flex',
        alignItems: 'center',
        ...shorthands.gap('6px'),
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('4px', '8px'),
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: tokens.colorPaletteGreenBackground1,
        color: tokens.colorPaletteGreenForeground1,
        ...shorthands.border(`1px solid ${tokens.colorNeutralStroke1}`),
        marginRight: '8px',
        cursor: 'pointer',
    },
    dot: {
        width: '6px',
        height: '6px',
        ...shorthands.borderRadius('50%'),
        backgroundColor: tokens.colorPaletteGreenForeground3,
    },
    tooltipContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('4px'),
    },
});

interface Props {
    children: React.ReactNode;
    personaName?: string | null;
    personaPrompt?: string | null;
}

export const Badge: FC<Props> = ({ children, personaName, personaPrompt }) => {
    const classes = useStyles();

    const badgeContent = (
        <span role="button" className={classes.badge}>
            <span className={classes.dot} />
            {children}
        </span>
    );

    const tooltipContent = (
        <div className={classes.tooltipContent}>
            <Text weight="semibold">Persona: {personaName}</Text>
            <Text>Prompt: {personaPrompt}</Text>
        </div>
    );

    if (personaName && personaPrompt) {
        return (
            <Tooltip content={tooltipContent} relationship="label">
                {badgeContent}
            </Tooltip>
        );
    }

    return badgeContent;
};

export default Badge;
