import { Navigate, Route, Routes } from 'react-router-dom';
import { BrandedSettingsType } from '../../App';
import Login from '../../pages/auth/login';


interface Props {
    brandingSettings: BrandedSettingsType;
}

const UnauthenticatedRoutes = ({brandingSettings}:Props) => {
    return (
        <Routes>
            <Route path="/login" element={<Login brandingSettings={brandingSettings} />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}

export default UnauthenticatedRoutes;
