/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
    Card,
    Field,
    Input,
    Textarea,
    Toaster,
    makeStyles,
    shorthands,
    tokens,
    typographyStyles,
} from '@fluentui/react-components';
import React from 'react';
import { useLoading } from '../../../../hooks';
import { styleConstants } from '../../../../libs/constants';
import { IPersona } from '../../../../libs/models/Persona';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import Button from '../../../shared/Button';
import ConfirmButton from '../../../shared/ConfirmButton';
import { useNotify } from '../../../shared/ToastHelper';
import PersonaAvatarView from '../PersonaAvatarView';
import { IPersonaSettings } from '../types';

const useClasses = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        ...shorthands.padding(tokens.spacingVerticalM),
        ...shorthands.gap(tokens.spacingVerticalL),
        ...styleConstants.visibleScrollbarStyles,
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 'auto',
    },
    prompt: {
        '& > .fui-Textarea__textarea': {
            maxHeight: '100%',
        },
    },
    sectionTitle: {
        ...typographyStyles.body1Stronger,
        whiteSpace: 'nowrap',
    },
    horizontal: {
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    dialog: {
        maxWidth: '25%',
    },
});

const PersonaGeneralSettings: React.FC<IPersonaSettings> = ({
    persona,
    handleSavePersona,
    handleDeletePersona,
    handleConfigurationChange,
    isDeleting,
    isSaving,
}) => {
    const classes = useClasses();
    const { loading, handleLoading } = useLoading();
    const { loading: deleting, handleLoading: handleDeleteLoading } = useLoading();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const { notify, toasterId } = useNotify();

    const loggedInUserId = activeUserInfo?.id?.split?.('.')?.[0];
    const isOwner = persona?.userId === loggedInUserId;

    const canSave = persona?.prompt && persona.name && !loading;

    const handleImageUpload = (url: IPersona['imageUrl']) => {
        if (!handleConfigurationChange) {
            return;
        }

        handleConfigurationChange('imageUrl', url);
    };

    const handlePromptChange = (value: string) => {
        if (!handleConfigurationChange) {
            return;
        }

        handleConfigurationChange('prompt', value);
    };

    const handleSave = () => {
        if (!handleSavePersona) {
            return;
        }

        if (persona && persona.name.length > 64) {
            notify({ title: 'Input Error', body: 'Persona name cannot exceed 64 characters.', type: 'warning' });
            return;
        }

        handleLoading(true);
        handleSavePersona();
        handleLoading(false);
    };

    const handleDelete = () => {
        if (!handleDeletePersona) {
            return;
        }

        handleDeleteLoading(true);
        handleDeletePersona();
        handleDeleteLoading(false);
    };

    return (
        <Card>
            <Toaster toasterId={toasterId} position="top-end" pauseOnHover />
            <div className={classes.container}>
                <PersonaAvatarView
                    imageUrl={persona?.imageUrl}
                    handleImageUpload={handleImageUpload}
                    personaId={persona?.id}
                    isOwner={isOwner}
                />
                <Field label="Persona name" style={{ maxWidth: '400px' }}>
                    <Input
                        value={persona?.name}
                        disabled={!isOwner}
                        onChange={(_, data) => {
                            handleConfigurationChange && handleConfigurationChange('personaName', data.value);
                        }}
                    />
                </Field>
                <Field label="Persona prompt">
                    <Textarea
                        resize="vertical"
                        value={persona?.prompt}
                        disabled={!isOwner}
                        onChange={(_event, data) => {
                            handlePromptChange(data.value);
                        }}
                        rows={8}
                        className={classes.prompt}
                    />
                </Field>
                <div className={classes.actionButtons}>
                    <Button
                        onClick={() => {
                            handleSave();
                        }}
                        appearance="primary"
                        disabled={!canSave || !isOwner}
                        loading={loading || isSaving}
                    >
                        Save
                    </Button>
                    {isOwner && handleDeletePersona && (
                        <ConfirmButton
                            actionButtonTitle="Delete"
                            confirmButtonTitle="Delete"
                            confirmModalTitle="Are you sure?"
                            confirmModalContent="You are about to delete this persona"
                            onConfirm={() => {
                                handleDelete();
                            }}
                            loading={deleting || loading || isDeleting}
                        />
                    )}
                </div>
            </div>
        </Card>
    );
};

export default PersonaGeneralSettings;
