import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React from 'react';
import { Breakpoints, SharedStyles } from '../../../styles';

const useStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...Breakpoints.small({
            justifyContent: 'center',
        }),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
        height: '32px',
        ...shorthands.borderRadius('50%'),
        backgroundColor: tokens.colorNeutralBackground3,
        animationName: {
            from: { opacity: 0.6 },
            to: { opacity: 1 },
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: tokens.spacingVerticalS,
        marginLeft: tokens.spacingHorizontalXS,
    },
    title: {
        height: '16px',
        width: '60%',
        backgroundColor: tokens.colorNeutralBackground3,
        ...SharedStyles.overflowEllipsis,
        animationName: {
            from: { opacity: 0.6 },
            to: { opacity: 1 },
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
    timestamp: {
        height: '14px',
        width: '20%',
        backgroundColor: tokens.colorNeutralBackground3,
        animationName: {
            from: { opacity: 0.6 },
            to: { opacity: 1 },
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
    previewText: {
        height: '14px',
        width: '80%',
        backgroundColor: tokens.colorNeutralBackground3,
        ...SharedStyles.overflowEllipsis,
        animationName: {
            from: { opacity: 0.6 },
            to: { opacity: 1 },
        },
        animationDuration: '1s',
        animationIterationCount: 'infinite',
        animationDirection: 'alternate',
    },
});

export const ChatListItemSkeleton: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.avatar} />
            <div className={classes.body}>
                <div className={classes.header}>
                    <div className={classes.title} />
                    <div className={classes.timestamp} />
                </div>
                {/* <div className={classes.previewText} /> */}
            </div>
        </div>
    );
};
