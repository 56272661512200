import { Button, Tooltip, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Checkmark20Regular, Copy20Regular, } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { IChatMessage } from '../../../libs/models/ChatMessage';

const useStyles = makeStyles({
    copyButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        position: 'absolute',
        top: '-22px',
        right: '0px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        color: tokens.colorNeutralForeground3,
        '&:hover': {
            color: tokens.colorBrandForeground1,
            backgroundColor: 'transparent',
        },
    },
    codeButton: {
        ...shorthands.padding('4px', '8px'),
        backgroundColor: tokens.colorNeutralBackground3,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: tokens.colorNeutralForeground1,
        '&:hover': {
            color: tokens.colorBrandForeground1,
            backgroundColor: 'transparent',
        },
    },
    buttonText: {
        marginLeft: '10px',
        fontWeight: tokens.fontWeightSemibold,
    },
    icon: {
        marginRight: '4px',
        marginTop: '1px',
    },
});

interface CopyButtonProps {
    isBot: boolean;
    message: IChatMessage;
    messages: IChatMessage[];
    isCodeSnippet: boolean;
    codeContent: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ isBot, message, messages, isCodeSnippet, codeContent }) => {
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const classes = useStyles();

    const handleButtonClick = () => {
        const textToCopy = isCodeSnippet ? codeContent : message.content; 
        navigator.clipboard
            .writeText(textToCopy)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1000);
            })
            .catch((error) => {
                console.error('Failed to write to clipboard: ', error);
            });
    };

    if (!isBot || message === messages[0]) return null;

    const IconComponent = isCopied ? Checkmark20Regular : Copy20Regular;

    return (
        <Tooltip content={isCopied ? 'Copied!' : 'Copy'} relationship="label">
            <Button
                style={{
                    border: 'none',
                }}
                onClick={handleButtonClick}
                className={isCodeSnippet ? classes.codeButton : classes.copyButton}
                appearance="transparent"
            >
                <IconComponent className={classes.icon} />
                {isCodeSnippet && <span className={classes.buttonText}>{isCopied ? 'Copied!' : 'Copy Code'}</span>}
            </Button>
        </Tooltip>
    );
};

export default CopyButton;
